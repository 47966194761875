import { useCallback, useEffect, useMemo, useState } from 'react'
import produce from 'immer'
import { QueryFunction, useInfiniteQuery, useQuery } from 'react-query'
import {
	filter,
	flatMap,
	isNot,
	isTruthy,
	map,
	pipe,
	prop,
	sortBy,
} from 'remeda'

import { optionIds } from '../../constants'
import type { ParentTreeTask, Tree } from '../../projects/projectsTypes'
import { parentStrategy } from '../../projects/strategies/parentStrategy'
import { Task } from '../../types'
import type { ApiAdapter } from '../api'
import { usePlayerId, usePlayerOption, usePlayerOptions } from '../hooks/player'
import {
	createFetchProjectListQueryFn,
	getProjectsOverviewListQueryKey,
} from '../projects/projects-queries'
import {
	createFetchFolderListQueryFn,
	createFetchTaskListQueryFn,
	taskKeys,
} from '../queries'
import { recentsKeys } from '../recents/recentsKeys'
import useStore from '../useStore'
import { getTasksFromIdsAsync } from '../utils'

interface UseProjectListIdsProps {
	filterFn?: (task: Task) => boolean
	inactiveFrom?: 'none' | 'today' | 'yesterday' | 'week' | 'month' | 'year'
}

interface UseProjectTasksProps {
	isNonParentsVisible: boolean
	focusedId: string | null
}

const fetchRecentProjects =
	(apiAdapter: ApiAdapter): QueryFunction<Task[]> =>
	async ({ signal }) => {
		const projectIds = await apiAdapter.player.getRecentlyViewedProjects()

		const { items } = await apiAdapter.tasks.getList(
			{ filterIds: projectIds?.join(',') },
			{ signal }
		)
		return sortBy(items, ({ id }) => projectIds.indexOf(id))
	}

// const fetchSearchResults =
// 	(
// 		apiAdapter: ApiAdapter,
// 		searchQuery: string
// 	): QueryFunction<ApiListResult<Task>> =>
// 	async ({ signal }) =>
// 		apiAdapter.tasks.getList(
// 			{
// 				search: searchQuery,
// 				statusCodes: 'active',
// 				pageSize: 30,
// 				page: 1,
// 			},
// 			{ signal }
// 		)

// const filterInvalidTasks = (tasks: Task[], taskIdsToMove: string[]) =>
// 	pipe(
// 		tasks,
// 		filter((task) => !taskIdsToMove.includes(task.id)),
// 		filter(
// 			(task) =>
// 				!task.parents?.find((parent) =>
// 					taskIdsToMove.includes(parent.id)
// 				)
// 		)
// 	)

const sortByTitle = sortBy((task: Task) => task.title)
export const useProjectListIds = (
	{
		filterFn = () => true,
		inactiveFrom = 'none',
	}: UseProjectListIdsProps = {},
	config = {}
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const playerId = usePlayerId()
	const { data: optionsData = {} } = usePlayerOptions([
		optionIds.STARRED_PROJECTS,
	])

	const { [optionIds.STARRED_PROJECTS]: starredProjects = [] } = optionsData

	const queryKey = useMemo(
		() => getProjectsOverviewListQueryKey(inactiveFrom),
		[inactiveFrom]
	)
	const { data, ...other } = useInfiniteQuery(
		queryKey,
		createFetchProjectListQueryFn({ apiAdapter, queryClient }),
		{
			getNextPageParam: (lastPage) =>
				lastPage?.hasMore ? lastPage.page + 1 : undefined,
			getPreviousPageParam: (firstPage) =>
				!firstPage || firstPage?.page === 1
					? undefined
					: firstPage.page - 1,
			...config,
		}
	)
	const projectBelongsToPlayer = (project: Task) =>
		project.ownerId === playerId

	const filteredProjects = useMemo(() => {
		if (data && Array.isArray(data.pages)) {
			return pipe(
				data.pages,
				filter(isTruthy),
				flatMap(prop('items')),
				map((task) =>
					starredProjects.includes(task.id)
						? { ...task, isStarred: true }
						: task
				),
				filter(filterFn)
			)
		} else {
			return []
		}
	}, [data, filterFn, starredProjects])
	const ownProjectIds: string[] = pipe(
		filteredProjects,
		filter(projectBelongsToPlayer),
		sortByTitle,
		map(prop('id'))
	)
	const sharedProjectIds: string[] = pipe(
		filteredProjects,
		filter(isNot(projectBelongsToPlayer)),
		sortByTitle,
		map(prop('id'))
	)
	const starredProjectIds: string[] = pipe(
		filteredProjects,
		filter(prop('isStarred')),
		sortByTitle,
		map(prop('id'))
	)
	const sections = {
		ownProjectIds,
		sharedProjectIds,
		starredProjectIds,
	}

	return {
		data: data
			? { projects: filteredProjects.map(prop('id')), sections }
			: undefined,
		...other,
	}
}

export const useProjectTasks = ({
	isNonParentsVisible,
	focusedId,
}: UseProjectTasksProps) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const [projectTasks, setProjectTasks] = useState<Task[]>([])
	const starredProjects = usePlayerOption(optionIds.STARRED_PROJECTS)

	const getQueryKey = useCallback(
		(parentId: string | null) =>
			taskKeys.list({
				hasChildren: !isNonParentsVisible,
				isMission: true,
				isProject: !parentId,
				parentId: parentId || undefined,
				statusCodes: 'active',
			}),
		[isNonParentsVisible]
	)
	const { data, ...queryResult } = useQuery(
		getQueryKey(focusedId),
		createFetchTaskListQueryFn(apiAdapter, queryClient)
	)

	useEffect(() => {
		if (!data) {
			return
		}

		// TODO: simplify this
		getTasksFromIdsAsync({ apiAdapter, queryClient }, data.items).then(
			(tasks) => {
				const tasksWithStarred = tasks.map((task) =>
					starredProjects.includes(task.id)
						? { ...task, isStarred: true }
						: task
				)
				setProjectTasks(tasksWithStarred)
				return tasks
			}
		)
	}, [apiAdapter, data, queryClient, starredProjects])

	const prefetch = useCallback(
		(projectId: string) =>
			queryClient.prefetchQuery(
				getQueryKey(projectId),
				createFetchTaskListQueryFn(apiAdapter, queryClient)
			),
		[apiAdapter, getQueryKey, queryClient]
	)

	return [{ ...queryResult, data: projectTasks }, prefetch] as const
}

const emptyTree: Tree<ParentTreeTask> = { rootId: null, items: {} }
export const useProjectTree = (projectId: string) => {
	const { apiAdapter } = useStore.getState()
	const [tree, setTree] = useState<Tree<ParentTreeTask>>(emptyTree)

	const { data: tasks = [], ...queryResult } = useQuery(
		taskKeys.list({
			ancestorId: projectId,
			includeRoot: true,
			pageSize: 0, // disable pagination
			statusCodes: 'active',
		}),
		async ({ queryKey, signal }) => {
			const result = await apiAdapter.tasks.getList(queryKey[2], {
				signal,
			})
			return result.items
		},
		{ enabled: Boolean(projectId) }
	)

	useEffect(() => {
		const parentTreeTask = (task: Task): ParentTreeTask => ({
			type: 'parent',
			...task,
			isMinimised: false,
		})
		const [rootTask, descendants] = tasks.reduce(
			(acc, task) => {
				if (task.id === projectId) {
					acc[0] = parentTreeTask(task)
				} else {
					acc[1].push(parentTreeTask(task))
				}
				return acc
			},
			[null, []] as [ParentTreeTask | null, ParentTreeTask[]]
		)
		if (rootTask) {
			const newTree = parentStrategy.buildTree(rootTask, descendants)
			setTree(newTree)
		}
	}, [projectId, tasks])

	return { data: tree, ...queryResult }
}

const getQueryKey = (parentId: string | null) =>
	taskKeys.list({
		isMission: true,
		isProject: !parentId,
		parentId,
		statusCodes: 'active',
	})

const patchTasksWithIsStarred = (tasks: Task[], starredIds: string[]) =>
	tasks.map((task) =>
		starredIds.includes(task.id)
			? produce(task, (draft) => {
					draft.isStarred = true
				})
			: task
	)

export const useFolderList = ({ focusedId }: { focusedId: string | null }) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const starredProjects = usePlayerOption(optionIds.STARRED_PROJECTS)

	return useQuery(
		getQueryKey(focusedId),
		createFetchFolderListQueryFn(apiAdapter, queryClient),
		{
			select: (data) =>
				patchTasksWithIsStarred(data.items, starredProjects),
		}
	)
}

export const useRecentProjects = () => {
	const { apiAdapter } = useStore.getState()
	return useQuery(['recentProjects'], fetchRecentProjects(apiAdapter))
}

export const useRecentProjectListIds = (params?: { limit: number }) => {
	const { apiAdapter } = useStore.getState()
	return useQuery<string[]>(recentsKeys.viewedProjects(), async () =>
		apiAdapter.player.getRecentlyViewedProjects(params)
	)
}

// export const useSearchProjectTasks = (query: string) => {
// 	const { apiAdapter } = useStore.getState()
// 	const { data: searchResults = [], isLoading: isLoadingSearchResults } =
// 		useQuery(['search', query], fetchSearchResults(apiAdapter, query), {
// 			enabled: Boolean(query),
// 			staleTime: 1000 * 5,
// 		})
// }
