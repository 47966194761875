import { Result } from 'neverthrow'

import { createError } from './errors/createError'

export const safeJsonStringify = Result.fromThrowable(
	JSON.stringify,
	createError
)

export const safeJsonParse = Result.fromThrowable(JSON.parse, createError)
