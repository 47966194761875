import { castDraft, produce } from 'immer'

import { DEFAULT_PAGINATION_SIZE } from '../constants'
import {
	ApiDatePaginationResult,
	ApiPagePaginationResult,
} from './api-adapter-types'

export const createEmptyPagePaginatedResult = <
	T = unknown,
>(): ApiPagePaginationResult<T> => ({
	count: 0,
	hasMore: false,
	items: [],
	page: 1,
	pageSize: DEFAULT_PAGINATION_SIZE,
})

export const appendToPagePaginatedResult = <T>(
	result: ApiPagePaginationResult<T>,
	items: T | T[]
) => {
	produce(result, (draft) => {
		if (Array.isArray(items)) {
			draft.count = draft.items.push(...castDraft(items))
		} else {
			draft.count = draft.items.push(castDraft(items))
		}
	})
}

export const createEmptyDatePaginatedList = <
	T = unknown,
>(): ApiDatePaginationResult<T> => ({
	count: 0,
	endCursor: new Date().toISOString(),
	hasMore: false,
	hasNextPage: false,
	hasPreviousPage: false,
	items: [],
	limit: DEFAULT_PAGINATION_SIZE,
	startCursor: new Date().toISOString(),
})
