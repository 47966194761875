//loosely based on https://www.behance.net/gallery/90781213/UVX

import { alpha, darken, emphasize } from '@mui/material/styles'

import { createBaseTheme } from './default'
import flatWhite from './flatWhite'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'
import { createPaletteColor } from './theme-utils'

// import babyBlueBackground from './wallpapers/background-babyBlue2.svg'

export const babyBlueTheme: CustomTheme = {
	name: 'babyBlue',
	//appBackground: { type: 'svg', svg: babyBlueBackground },
	palette: {
		...defaultPalette,
		primary: '#59C2C8',
		neutral: '#EDF4FA',
		background: '#C6DFED',
		sidebar: '#EDF4FA',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'light' } })
	const flatWhiteMuiTheme = flatWhite()

	const fontFamily = `Poppins, ${baseMuiTheme.typography.fontFamily}`

	return createBaseTheme({
		name: 'babyBlue',
		//appBackgroundImage: `url("${babyBlueBackground}")`,
		typography: {
			fontFamily,
			h1: { fontWeight: 500 },
			h2: { fontWeight: 500 },
			h3: { fontWeight: 700 },
			h4: { fontWeight: 700 },
			h5: { fontWeight: 700 },
			h6: { fontWeight: 700 },
		},

		palette: {
			primary: createPaletteColor(babyBlueTheme.palette.primary, {
				contrastText: '#fff',
			}),
			neutral: createPaletteColor(babyBlueTheme.palette.neutral),
			action: {
				hover: babyBlueTheme.palette.neutral,
			},
			background: {
				contextPanel: emphasize(
					alpha(babyBlueTheme.palette.background, 0.075),
					1
				),
				default: babyBlueTheme.palette.background,
				sidebar: 'transparent',
			},
			wallpaper: flatWhiteMuiTheme.palette,
		},

		components: {
			TyTabBar: {
				styleOverrides: {
					root: {
						backgroundColor: baseMuiTheme.palette.background.paper,
					},
				},
			},
			TyTabBarItem: {
				styleOverrides: {
					root: {
						backgroundColor: alpha(
							darken(babyBlueTheme.palette.background, 0.2),
							0.2
						),
					},
					selected: {
						backgroundColor: babyBlueTheme.palette.background,
					},
				},
			},
		},
	})
}

export default createMuiTheme
