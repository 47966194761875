import { themesOrder } from './theme-constants'
import {
	AppBackgroundGradient,
	AppBackgroundImage,
	AppBackgroundSvg,
	CustomThemeBackground,
	ThemeId,
} from './theme-types'

export const isAppBackgroundGradient = (
	background: CustomThemeBackground
): background is AppBackgroundGradient => background.type === 'gradient'
export const isAppBackgroundImage = (
	background: CustomThemeBackground
): background is AppBackgroundImage => background.type === 'image'
export const isAppBackgroundSvg = (
	background: CustomThemeBackground
): background is AppBackgroundSvg => background.type === 'svg'

export const isThemeId = (id: string): id is ThemeId =>
	themesOrder.includes(id as ThemeId)
