import { Slice } from '../../types'
import { MutatedAppState } from '../store-types'

export type SettingsSlice = Slice & {
	disableUser: (userId: string) => Promise<{ success: boolean }>
}

/* Keeping this for now as an rxjs reference
const createFetchSettingsTeam =
	(apiAdapter: ApiAdapter) =>
	({ queryKey, signal }: QueryFunctionContext<UserKeysList>) =>
		apiAdapter.users.getList(queryKey[2], { signal })

const settingsTeamSubject = new BehaviorSubject<User[]>([])

const createTeamObservable = (
	apiAdapter$: Observable<ApiAdapter>,
	queryClient$: Observable<QueryClient>
) =>
	merge(
		settingsTeamSubject,
		// ReactQuery handling caching and keeping this value up to date
		combineLatest([apiAdapter$, queryClient$]).pipe(
			switchMap(([apiAdapter, queryClient]) =>
				createQueryObservable(queryClient, {
					queryKey: userKeys.list({}),
					queryFn: createFetchSettingsTeam(apiAdapter),
				})
			),
			map((result) => result.data?.items ?? [])
		)
	)

const createUserUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ id: string } & Partial<User>>(
		socket,
		events.USER_UPDATE
	)

const applyTeamUpdate = (
	users: User[],
	userPatch: { id: string } & Partial<User>
) =>
	produce(users, (draft) => {
		const index = draft.findIndex((user) => user.id === userPatch.id)
		if (index === -1) return
		draft[index] = {
			...draft[index],
			...userPatch,
		}
	}) */

export const createSettingsSlice: MutatedAppState<SettingsSlice> = (
	_set,
	get
) => ({
	init: () => {
		return () => {
			//
		}
	},
	disableUser: async (userId) => {
		return get().apiAdapter.users.disableUser(userId)
	},
})

export default createSettingsSlice
