import { formatISO } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import produce from 'immer'
import { forEach } from 'ramda'
import { sample } from 'remeda'
import { GetState } from 'zustand'

import { flattenAndUnwrapTree, type TreeNode } from '@tyto/tree-utils'
import { createError } from '@tyto/utils/errors'

import { createTaskActivity } from '../task-activity/createTaskActivity'
import {
	AddTaskPayload,
	Task,
	TaskFollower,
	TaskPosition,
	User,
	Workflow,
	WorkflowIndex,
} from '../types'
import { ApiResult } from './api'
import {
	createRemoveFollowerFromQueryCache,
	removeFollowerMutation,
} from './mutations/followers'
import { createAddFollowerFromQueryCache } from './mutations/followers/add'
import {
	addTaskMutation,
	createAddTaskToApi,
	createMoveTaskOnQueryCache,
	createUpdateBulkTasksOnApi,
	createUpdateTaskOnQueryCache,
	markAsDoneMutation,
	moveBulkTasksMutation,
	moveTaskMutation,
	removeTaskMutation,
	updateTaskMutation,
} from './mutations/tasks'
import {
	pauseTaskMutation,
	startTaskMutation,
	stopTaskMutation,
} from './mutations/timer'
import { updateUserMutation } from './mutations/users'
import {
	addWorkflowMutation,
	removeWorkflowMutation,
	updateWorkflowMutation,
} from './mutations/workflows'
import {
	addTaskSubject,
	addWorkflowSubject,
	moveTaskSubject,
	removeTaskSubject,
	removeWorkflowSubject,
	updateTaskSubject,
	updateUserSubject,
	updateWorkflowSubject,
} from './observables'
import { taskKeys, userKeys, workflowKeys } from './queries'
import {
	createAddTaskFailedSnackbar,
	createAddTaskSnackbar,
	createUpdateBulkTaskSnackbar,
	createUpdateTaskSnackbar,
} from './snackbars'
import { isStoreError } from './store-type-guards'
import {
	AppState,
	MutatedAppState,
	StoreError,
	StoreNotification,
} from './store-types'
import { taskActivityKeys } from './task-activity/taskActivityKeys'
import { createAddTaskActivityToQueryCache } from './task-activity/taskActivityMutations'
import makePreTaskUpdate from './utils/makePreTaskUpdate'
import normaliseTask, { isNormalisedTaskResult } from './utils/normaliseTask'
import { validateWorkflow } from './validations'

type MoveOptions = {
	optimisticOnly?: boolean
}

type MarkAsDoneExtraData = {
	draftModel: RawDraftContentState
	html: string
	markDescendantsAsDone: boolean
}
export interface StoreActions {
	// Team actions
	addFollower: (taskId: string, followerId: TaskFollower) => void
	updateBulkFollowers: (taskId: string, followerIds: TaskFollower[]) => void
	removeFollower: (taskId: string, followerId: string) => void
	setAssignee: (taskId: string, assigneeId: string) => void
	setOwner: (taskId: string, ownerId: string) => void

	// Task actions
	addTask: (
		task: AddTaskPayload,
		position?: TaskPosition
	) => Promise<Task | StoreError>
	cloneProject: (tree: TreeNode) => void
	markTaskAsDone: (taskId: string, extraData: MarkAsDoneExtraData) => void
	markTaskAsNotDone: (taskId: string) => void
	moveTask: (
		taskId: string,
		destination: TaskPosition,
		options?: MoveOptions
	) => void
	moveBulkTasks: (
		taskIds: string[],
		destination: TaskPosition,
		options?: MoveOptions
	) => void
	removeTask: (taskId: string) => void
	updateTask: (
		taskId: string,
		changes: Partial<Task>
	) => Promise<ApiResult<Task> | Error>
	updateBulkTasks: (taskIds: string[], changes: Partial<Task>) => void

	// Timer actions
	pauseTask: (taskId: string) => void
	startTask: (taskId: string) => void
	stopTask: (taskId: string) => void

	// User actions
	updatePlayer: (changes: Partial<User>) => void
	updateUser: (userId: string, changes: Partial<User>) => void

	// Workflow actions
	addWorkflow: (workflow: Workflow) => Promise<Workflow>
	removeWorkflow: (workflowId: string) => void
	updateWorkflow: (workflowId: string, changes: Partial<Workflow>) => void
}

export interface MovePosition {
	parentId: string | null
}
export interface MoveTaskAction {
	taskId: string
	destination: TaskPosition
	source: TaskPosition
}
export interface UpdateTaskAction {
	taskId: string
	changes: Partial<Task>
	oldTask?: Task
}

export interface UpdateUserAction {
	userId: string
	changes: Partial<User>
}
export interface UpdateWorkflowAction {
	workflowId: string
	changes: Partial<Workflow>
}

const applyPreTaskUpdate = (
	state: AppState,
	taskId: string,
	changes: Partial<Task>
) => {
	const queryClient = state.queryClient
	const workflowById = queryClient.getQueryData<WorkflowIndex>(
		workflowKeys.list()
	)

	// TODO: lazy load workflows if undefined
	if (!workflowById) {
		return { assignee: undefined, task: changes }
	}

	const oldTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	if (!oldTask) {
		return { assignee: undefined, task: changes }
	}

	const oldAssignee = oldTask.assigneeId
		? queryClient.getQueryData<User>(userKeys.detail(oldTask.assigneeId))
		: null

	const preTaskUpdate = makePreTaskUpdate(workflowById, oldAssignee)
	const data = preTaskUpdate(oldTask, changes)

	// If there is no assigneeId and the startDate changed to a non-null value, then set assigneeId to playerId
	if (
		data.task.startDate !== oldTask.startDate &&
		data.task.startDate &&
		!oldTask.assigneeId &&
		!data.task.assigneeId
	) {
		data.task.assigneeId = state.player.id
	}

	return data
}

const createAddTask =
	(get: GetState<AppState>) =>
	async (task: AddTaskPayload, position?: TaskPosition) => {
		// If we can't pre-apply task defaults, then continue with what we have,
		// the task may be incomplete but at least send it off to the API to
		// persist any data collected.
		const addTaskSnackbar = createAddTaskSnackbar()
		const addTaskFailedSnackbar = createAddTaskFailedSnackbar()
		const normalisedResult = normaliseTask(get(), task)
		const errors$ = get().errors
		const notifications$ = get().notifications

		let result: Task | null
		if (isStoreError(normalisedResult)) {
			const addTaskToApi = createAddTaskToApi(get().apiAdapter)
			result = await addTaskToApi(task, position)
			if (result) {
				addTaskSubject.next({ position, task: result })
				if (isNormalisedTaskResult(normalisedResult)) {
					notifications$.next(
						addTaskSnackbar(normalisedResult.task.id)
					)
				}
			} else {
				notifications$.next(addTaskFailedSnackbar())
			}
		} else {
			const newTask = normalisedResult.task
			addTaskSubject.next({ position, task: newTask })
			const subscription = addTaskMutation(
				get(),
				newTask,
				position
			).subscribe({
				next: (result) => {
					if (result.isIdle) {
						result.mutate()
					}

					if (result.data) {
						notifications$.next(addTaskSnackbar(result.data.id))
						subscription.unsubscribe()
					}

					// Invalidate task activity query in case it's already loaded as a empty array
					get().queryClient.invalidateQueries(
						taskActivityKeys.list(newTask.id)
					)
				},
				error: (err) => {
					errors$.next({
						error: err,
						message: 'Failed to create task',
					})
					notifications$.next(addTaskFailedSnackbar())
					subscription.unsubscribe()
				},
			})
			result = normalisedResult.task

			if (normalisedResult.failed.length > 0) {
				const message = `Couldn't save the following data: ${normalisedResult.failed.join(
					', '
				)}`

				errors$.next({ error: new Error(message), message })
			}
		}

		return result
	}

const createMarkTaskAsDone =
	(get: GetState<AppState>): StoreActions['markTaskAsDone'] =>
	async (taskId, { draftModel, html, markDescendantsAsDone }) => {
		const errors$ = get().errors
		const notifications$ = get().notifications

		const newChanges = applyPreTaskUpdate(get(), taskId, {
			doneDate: formatISO(Date.now()),
			statusCode: 'done',
		})

		// There will sometimes be assignee changes to update
		if (newChanges.assignee && newChanges.assignee.id) {
			const userId = newChanges.assignee.id
			updateUserMutation(get(), userId, newChanges.assignee)
			updateUserSubject.next({ userId, changes: newChanges.assignee })
		}

		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		// TODO: move to rxjs
		const result = markAsDoneMutation(get(), taskId, { draftModel, html })
			.then((result) => {
				updateTaskSubject.next({
					taskId,
					changes: newChanges.task,
					oldTask,
				})
				return result
			})
			.catch((err) => {
				errors$.next({
					error: err,
					message: 'Failed to mark task as done',
				})
			})

		if (markDescendantsAsDone) {
			// FIXME - this needs to be tested first
			// descendants
			// 	.map((i) => i.id)
			// 	.forEach((id) => markTaskAsDone({ taskId: id }))
		}

		// Stop timer if it's started
		if (oldTask?.currentTimer.status === 'started' || false) {
			get().stopTask(taskId)
		}

		// The update snackbar handles done actions when statusCode === 'done'
		const updateTaskSnackbar = createUpdateTaskSnackbar()
		const snackbar = updateTaskSnackbar(newChanges.task)
		if (snackbar) {
			notifications$.next(snackbar)
		}

		return result
	}

const createUpdateTask =
	(get: GetState<AppState>): StoreActions['updateTask'] =>
	async (taskId, changes): Promise<ApiResult<Task> | Error> => {
		// There are side-effect changes that may need to happen as a result of
		// the update.
		const newChanges = applyPreTaskUpdate(get(), taskId, changes)
		const errors$ = get().errors
		const notifications$ = get().notifications

		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)

		console.debug('update task', { changes, newChanges, oldTask })
		const result = updateTaskMutation(get(), taskId, newChanges.task).catch(
			(err) => {
				errors$.next({
					error: err,
					message: 'Failed to update task',
				})
				return createError(err)
			}
		)

		updateTaskSubject.next({
			taskId,
			changes: newChanges.task,
			oldTask,
		})

		// There will sometimes be assignee changes to update
		if (newChanges.assignee) {
			const userId = newChanges.assignee.id
			updateUserMutation(get(), userId, newChanges.assignee)
			updateUserSubject.next({ userId, changes: newChanges.assignee })
		}

		const updateTaskSnackbar = createUpdateTaskSnackbar()
		const snackbar = updateTaskSnackbar(newChanges.task)
		if (snackbar) {
			notifications$.next(snackbar)
		}

		return result
	}

const createUpdateUser =
	(get: GetState<AppState>): StoreActions['updateUser'] =>
	(userId, changes) => {
		updateUserMutation(get(), userId, changes)
		updateUserSubject.next({ userId, changes })
	}

const createActions: MutatedAppState<StoreActions> = (set, get) => ({
	// Team actions
	updateBulkFollowers: (taskId, followers) => {
		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		const queryClient = get().queryClient
		const addFollowerOnQueryCache =
			createAddFollowerFromQueryCache(queryClient)

		if (oldTask) {
			// delete followers from cache
			oldTask.followers.forEach(
				(oldFollower) =>
					!followers.find(
						(newFollower) => newFollower.id !== oldFollower.id
					) &&
					createRemoveFollowerFromQueryCache(queryClient)(
						taskId,
						oldFollower.id
					)
			)

			// add followers to cache
			followers.forEach(
				(follower) =>
					!oldTask.followers.find(
						(oldFollower) => oldFollower.id !== follower.id
					) && addFollowerOnQueryCache(taskId, follower)
			)

			// update followers
			get().updateTask(taskId, { followers })
		}
	},
	addFollower: (taskId, follower) => {
		//addFollowerMutation(get(), taskId, follower)
		const addFollowerOnQueryCache = createAddFollowerFromQueryCache(
			get().queryClient
		)
		addFollowerOnQueryCache(taskId, follower)
		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (oldTask) {
			const followers = [...oldTask.followers]
			followers.push(follower)
			get().updateTask(taskId, { followers })
		}
	},
	removeFollower: (taskId, followerId) => {
		removeFollowerMutation(get(), taskId, followerId)
	},
	setAssignee: (taskId, assigneeId) => {
		get().updateTask(taskId, { assigneeId })
		const updateTaskOnQueryCache = createUpdateTaskOnQueryCache(
			get().queryClient
		)
		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (oldTask) {
			const oldAssigneeId = oldTask.assigneeId
			const oldOwnerId = oldTask.ownerId
			const newFollowers =
				oldTask.followers?.filter(
					(follower) => follower.id !== assigneeId
				) || []
			if (
				oldAssigneeId &&
				oldAssigneeId !== oldOwnerId &&
				assigneeId !== oldAssigneeId
			) {
				newFollowers.push({
					id: oldAssigneeId,
					isVirtual: false,
					roles: 'follower',
				})
			}
			updateTaskOnQueryCache(taskId, { followers: newFollowers })
		}
	},
	setOwner: (taskId, ownerId) => {
		get().updateTask(taskId, { ownerId })
		const updateTaskOnQueryCache = createUpdateTaskOnQueryCache(
			get().queryClient
		)
		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (oldTask) {
			const oldAssigneeId = oldTask.assigneeId
			const oldOwnerId = oldTask.ownerId
			const newFollowers = oldTask.followers.filter(
				(follower) => follower.id !== ownerId
			)
			if (
				oldOwnerId &&
				oldAssigneeId !== oldOwnerId &&
				ownerId !== oldOwnerId
			) {
				newFollowers.push({
					id: oldOwnerId,
					isVirtual: false,
					roles: 'follower',
				})
			}
			updateTaskOnQueryCache(taskId, { followers: newFollowers })
		}
	},

	// Task actions
	addTask: async (task, position) => {
		if (typeof task.title !== 'string' || !task.title) {
			return Promise.resolve({
				error: new Error('Task requires a title'),
				message: 'Task requires a title',
			})
		}
		const newTask = await createAddTask(get)(task, position)
		if (newTask != null) {
			return newTask
		} else {
			return Promise.resolve({
				error: new Error(`Couldn't create task`),
				message: `Couldn't create task`,
			})
		}
	},
	cloneProject: async (tree) => {
		const { apiAdapter, notifications, queryClient } = get()
		const flatTree = flattenAndUnwrapTree(tree)

		const projectsKey = taskKeys.list({ isProject: true })

		for (const task of flatTree) {
			queryClient.setQueryData(taskKeys.detail(task.id), task)
		}

		const rootTask = tree.data
		queryClient.setQueriesData(
			projectsKey,
			produce((draft) => {
				if (draft?.pages) {
					draft.pages[0].items.push(rootTask)
				} else if (draft?.items) {
					draft.items.push(rootTask)
				}
			})
		)

		try {
			const tasks = await apiAdapter.tasks.addMultiple(flatTree)
			if (tasks) {
				// TODO: trigger this from the dialog. Perhaps pass props into cloneProject for an inversion of control patter
				notifications.next({
					type: 'snackbar',
					message: `Successfully cloned ${tree.data.title}`,
					link: 'View',
					linkProps: { to: `/projects/${rootTask.id}` },
				})
			}
			return tasks
		} catch (err) {
			const failedMessages = [
				'😱 Task save failed. Please resort to pen and paper.',
				'🤦🏻‍ Computer says no. Please try again later.',
			] as const
			notifications.next({
				type: 'snackbar',
				message: sample(failedMessages, 1)[0],
			})

			throw err
		}
	},
	// Both draftModel and html fields must be provided if the user comments
	// while marking as done. Leave out if no comment is provided.
	markTaskAsDone: (taskId, { draftModel, html, markDescendantsAsDone }) => {
		const markTaskAsDone = createMarkTaskAsDone(get)
		return markTaskAsDone(taskId, {
			draftModel,
			html,
			markDescendantsAsDone,
		})
	},
	markTaskAsNotDone: (taskId) => {
		return get().updateTask(taskId, { statusCode: 'new' })
	},
	moveTask: (taskId, destination, options = {}) => {
		const queryClient = get().queryClient
		const oldTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		const sourceParentTask = oldTask?.parentId
			? queryClient.getQueryData<Task>(taskKeys.detail(oldTask.parentId))
			: null

		const source: TaskPosition = oldTask?.parentId
			? {
					parentId: oldTask.parentId,
					childSortOrder: sourceParentTask
						? sourceParentTask.childSortOrder
						: [],
				}
			: { parentId: null } // Not sure what a good fallback is here. OldTask may not exist in the cache.
		let result
		if (options.optimisticOnly) {
			const moveTaskOnQueryCache = createMoveTaskOnQueryCache(queryClient)
			result = moveTaskOnQueryCache(taskId, destination)
		} else {
			result = moveTaskMutation(get(), taskId, destination)
		}
		moveTaskSubject.next({ taskId, destination, source })
		return result
	},
	moveBulkTasks: (taskIds, destination, options = {}) => {
		const queryClient = get().queryClient
		const notifications$ = get().notifications

		const sourceIndex: Record<string, TaskPosition> = {}
		taskIds.forEach((taskId) => {
			const oldTask = queryClient.getQueryData<Task>(
				taskKeys.detail(taskId)
			)
			const sourceParentTask = oldTask?.parentId
				? queryClient.getQueryData<Task>(
						taskKeys.detail(oldTask.parentId)
					)
				: null

			sourceIndex[taskId] = oldTask?.parentId
				? {
						parentId: oldTask.parentId,
						childSortOrder: sourceParentTask
							? sourceParentTask.childSortOrder
							: [],
					}
				: { parentId: null } // Not sure what a good fallback is here. OldTask may not exist in the cache.
		})

		if (options.optimisticOnly) {
			const moveTaskOnQueryCache = createMoveTaskOnQueryCache(queryClient)
			taskIds.forEach((taskId) => {
				moveTaskOnQueryCache(taskId, destination)
			})
		} else {
			moveBulkTasksMutation(get(), taskIds, destination)
		}

		taskIds.forEach((taskId) => {
			const source = sourceIndex[taskId]
			moveTaskSubject.next({ taskId, destination, source })
		})

		// Create toast for moving tasks
		const parentTask = queryClient.getQueryData<Task>(
			taskKeys.detail(destination.parentId || '')
		)
		const parents = parentTask?.parents

		const snackbar: StoreNotification = {
			type: 'snackbar',
			message: `Moved ${taskIds.length} tasks`,
		}
		if (parentTask) {
			let titles: string[] = []
			if (parents) {
				titles = parents.map((crumb) => crumb.title)
			}
			titles.push(parentTask.title)
			const breadcrumb = titles.join('/')
			snackbar.message = `Moved ${taskIds.length} tasks to ${breadcrumb}`
		}

		notifications$.next(snackbar)
	},
	removeTask: (taskId) => {
		removeTaskMutation(get(), taskId)
		removeTaskSubject.next(taskId)
	},
	updateTask: async (taskId, changes) => {
		const oldTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)

		const updateTask = createUpdateTask(get)
		const newTask = await updateTask(taskId, changes)

		// If assignee changed, and task is started. Then stop the task for the old assignee.
		if (
			!(newTask instanceof Error) &&
			newTask?.assigneeId !== oldTask?.assigneeId &&
			oldTask?.assigneeId &&
			oldTask?.currentTimer.status === 'started'
		) {
			const updateUser = createUpdateUser(get)
			updateUser(oldTask.assigneeId, {
				currentTaskId: null,
				currentTaskStartDate: null,
			})
		}

		return newTask
	},
	updateBulkTasks: (taskIds, changes) => {
		const notifications$ = get().notifications
		const updateTaskOnQueryCache = createUpdateTaskOnQueryCache(
			get().queryClient
		)
		forEach((taskId) => {
			const oldTask = get().queryClient.getQueryData<Task>(
				taskKeys.detail(taskId)
			)
			const newChanges = applyPreTaskUpdate(get(), taskId, changes)

			updateTaskOnQueryCache(taskId, newChanges.task)
			updateTaskSubject.next({
				taskId,
				changes: newChanges.task,
				oldTask,
			})
		}, taskIds)

		const updateBulkTasksOnApi = createUpdateBulkTasksOnApi(
			get().apiAdapter
		)
		updateBulkTasksOnApi(taskIds, changes)

		const updateBulkTaskSnackbar = createUpdateBulkTaskSnackbar(get())
		const snackbar = updateBulkTaskSnackbar({ taskIds, changes })
		if (snackbar) {
			notifications$.next(snackbar)
		}
	},

	// Timer actions
	pauseTask: (taskId) => {
		const queryClient = get().queryClient
		const oldTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))

		const updateTask = createUpdateTask(get)
		const updateUser = createUpdateUser(get)
		pauseTaskMutation(get(), taskId, { updateTask, updateUser })

		const playerId = get().player.id
		if (!playerId) {
			throw new Error('playerId is falsey in AppState')
		}
		if (!oldTask) {
			throw new Error(
				'Cannot create optimistic task activity for pauseTask action without a task in cache'
			)
		}
		const newTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		if (!newTask) {
			throw new Error(
				'Cannot create optimistic task activity for pauseTask action without a task in cache'
			)
		}
		const activity = createTaskActivity(playerId, oldTask, newTask)
		if (activity) {
			createAddTaskActivityToQueryCache(apiAdapter, queryClient)(activity)
		}
	},
	startTask: (taskId) => {
		const updateTask = createUpdateTask(get)
		const updateUser = createUpdateUser(get)
		startTaskMutation(get(), taskId, { updateTask, updateUser })
	},
	stopTask: (taskId) => {
		const updateTask = createUpdateTask(get)
		const updateUser = createUpdateUser(get)
		stopTaskMutation(get(), taskId, { updateTask, updateUser })
	},

	// User actions
	updatePlayer: (changes) => {
		const { apiAdapter, player } = get()
		apiAdapter.player.update(changes)

		if (player.id) {
			const updateUser = createUpdateUser(get)
			updateUser(player.id, changes)
		}
	},
	updateUser: (userId, changes) => {
		const updateUser = createUpdateUser(get)
		updateUser(userId, changes)
	},

	// Workflow actions
	addWorkflow: validateWorkflow((workflow) => {
		const result = addWorkflowMutation(get(), workflow)
		addWorkflowSubject.next(workflow)
		return result
	}),
	removeWorkflow: (workflowId) => {
		removeWorkflowMutation(get(), workflowId)
		removeWorkflowSubject.next(workflowId)
	},
	updateWorkflow: (workflowId, changes) => {
		updateWorkflowMutation(get(), workflowId, changes)
		updateWorkflowSubject.next({ workflowId, changes })
	},
})

export { createActions as default }
