import { formatISO } from 'date-fns'
import produce from 'immer'
import { Subject } from 'rxjs'
import { debounceTime, tap, throttleTime } from 'rxjs/operators'

import { createChatMessageId } from '../../helpers/createId'
import { convertApiFileToAttachment } from '../../helpers/files'
import {
	FileOrMobileFile,
	FileWithId,
	MobileFile,
	Slice,
	User,
} from '../../types'
import { ApiFile, ApiListResult } from '../api'
import {
	chatMessageFileSubject,
	chatMessageSubject,
	chatReactionsSubject,
	typingInChatSubject,
} from '../observables'
import { userKeys } from '../queries'
import { MutatedAppState, MutatedSetState } from '../store-types'
import { isMobileFile } from '../utils/files'
import { chatKeys } from './chatKeys'
import {
	createAddChatRoomMutationObserver,
	createAddChatRoomOptimisticHandler,
	createAddMessageFileOptimisticHandler,
	createAddMessageReactionMutationObserver,
	createArchiveRoomOptimisticHandler,
	createChatMessageReactionsOptimisticHandler,
	createRemoveMessageReactionMutationObserver,
	createSendMessageFilesMutationObserver,
	createSendMessageMutationObserver,
	createSendMessageOptimisticHandler,
	createUpdateChatRoomMutationObserver,
	createUpdateLastReadDateMutationObserver,
} from './chatMutations'
import { ChatMessage, ChatRoom } from './chatTypes'
import { createChatRoom } from './chatUtils'

type GroupChatRoomProps = { name: string; users: [string, string, ...string[]] }
type UserChatRoomProps = { name?: void; users: [string] }
type CreateChatRoomProps = GroupChatRoomProps | UserChatRoomProps

export interface ChatSlice extends Slice {
	currentChatRoomId: string | null
	roomDrafts: Record<string, string>
	roomFiles: Record<string, FileOrMobileFile[]>
	roomReplies: Record<string, ChatMessage | null>
	usersTyping: Record<string, string[]>
	addReaction: (message: ChatMessage, reaction: string) => void
	removeReaction: (message: ChatMessage, reaction: string) => void

	// Rooms
	archiveRoom: (roomId: string) => void
	closeAllChatRooms: () => void
	closeChatRoom: (roomId: string) => void
	createChatRoom: (
		chatRoom: CreateChatRoomProps
	) => Promise<
		Pick<ChatRoom, 'name' | 'users' | 'id' | 'roomType'> | undefined
	>
	updateChatRoom: (
		roomId: string,
		chatRoom: Pick<ChatRoom, 'name' | 'users'>
	) => void
	openChatRoom: (roomId: string) => void
	getRoomDraft: (roomId: string) => string
	saveRoomDraft: (roomId: string, message: string) => void
	getRoomFiles: (roomId: string) => FileOrMobileFile[]
	saveRoomFiles: (roomId: string, files: (File | MobileFile)[]) => void
	getRoomReply: (roomId: string) => ChatMessage | null
	saveRoomReply: (roomId: string, message: ChatMessage | null) => void
	unarchiveRoom: (roomId: string) => void

	sendMessage: (
		roomId: string,
		message: string,
		replyToId?: string | null
	) => void
	typing: (roomId: string) => void
	updateLastReadDate: (roomId: string, lastReadDate?: string) => void
}

const typing$ = new Subject<string>()

const addTypingUser = (
	set: MutatedSetState,
	roomId: string,
	userId: string
) => {
	set((draft) => {
		const usersTyping = draft.chat.usersTyping
		if (!usersTyping[roomId]) {
			usersTyping[roomId] = []
		}
		if (usersTyping[roomId].indexOf(userId) === -1) {
			usersTyping[roomId].push(userId)
		}
	})
}

const removeTypingUser = (
	set: MutatedSetState,
	roomId: string,
	userId: string
) => {
	set((draft) => {
		const usersTyping = draft.chat.usersTyping
		if (!usersTyping[roomId]) {
			return
		}
		const index = usersTyping[roomId].indexOf(userId)
		usersTyping[roomId].splice(index, 1)
	})
}

export const createChatSlice: MutatedAppState<ChatSlice> = (set, get) => ({
	currentChatRoomId: null,
	roomDrafts: {},
	roomFiles: {},
	roomReplies: {},
	usersTyping: {},
	init: () => {
		const { apiAdapter, queryClient } = get()

		const chatMessageSubscription = chatMessageSubject
			.pipe(
				tap((chatMessage) => {
					removeTypingUser(
						set,
						chatMessage.chatRoomId,
						chatMessage.userId
					)
				})
			)
			.subscribe((chatMessage) => {
				const sendMessageOptimisticHandler =
					createSendMessageOptimisticHandler(queryClient)
				sendMessageOptimisticHandler(chatMessage)
			})

		const chatUnreadMessagesSubscription = chatMessageSubject
			.pipe(
				tap((chatMessage) => {
					queryClient.setQueryData(
						chatKeys.allMessagesList({
							isUnread: true,
							pageSize: 0,
						}),
						produce<ApiListResult<ChatMessage>>((draft) => {
							if (
								!draft ||
								!draft.items ||
								// Don't add duplicates
								draft.items.find(
									(item) => item.id === chatMessage.id
								)
							) {
								return
							}
							draft.items.unshift(chatMessage)
						})
					)
				})
			)
			.subscribe()

		const chatMessageFileSubscription = chatMessageFileSubject.subscribe(
			(file: ApiFile) => {
				const attachment = convertApiFileToAttachment(
					apiAdapter.apiInstance.defaults.baseURL || '',
					get().player.authToken || '',
					file
				)
				createAddMessageFileOptimisticHandler(queryClient)(
					file.chatMessageId,
					attachment
				)
			}
		)

		const chatReactionsSubscription = chatReactionsSubject
			.pipe(
				tap(async (chatReactionSocket) => {
					const updateChatMessageReactions =
						createChatMessageReactionsOptimisticHandler(queryClient)
					updateChatMessageReactions(chatReactionSocket)
				})
			)
			.subscribe()

		const typingSubscription = typing$
			.pipe(
				throttleTime(3000),
				tap((roomId) => {
					apiAdapter.chat.typing(roomId)
				})
			)
			.subscribe()

		const typingInChatSubscription = typingInChatSubject
			.pipe(
				tap(({ chatRoomId, typingUserId }) =>
					addTypingUser(set, chatRoomId, typingUserId)
				),
				debounceTime(5000),
				tap(({ chatRoomId, typingUserId }) =>
					removeTypingUser(set, chatRoomId, typingUserId)
				)
			)
			.subscribe()

		return () => {
			chatMessageSubscription.unsubscribe()
			chatMessageFileSubscription.unsubscribe()
			chatReactionsSubscription.unsubscribe()
			chatUnreadMessagesSubscription.unsubscribe()
			typingSubscription.unsubscribe()
			typingInChatSubscription.unsubscribe()
		}
	},
	addReaction: async (message: ChatMessage, reaction: string) => {
		const { apiAdapter, queryClient } = get()

		const addReactionObserver = createAddMessageReactionMutationObserver({
			apiAdapter,
			queryClient,
		})

		return addReactionObserver.mutate({ message, reaction })
	},
	removeReaction: async (message: ChatMessage, reaction: string) => {
		const { apiAdapter, queryClient } = get()

		const removeReactionObserver =
			createRemoveMessageReactionMutationObserver({
				apiAdapter,
				queryClient,
			})

		return removeReactionObserver.mutate({ message, reaction })
	},
	archiveRoom: (roomId: string) => {
		const { apiAdapter, queryClient } = get()
		apiAdapter.chat.archiveRoom(roomId)
		createArchiveRoomOptimisticHandler(queryClient)(roomId)
	},
	unarchiveRoom: (roomId: string) => {
		const { apiAdapter } = get()
		apiAdapter.chat.archiveRoom(roomId, { archive: false })
	},
	closeAllChatRooms: () => {
		// Note: In future, we would iterate over all chat rooms and close them
		set((draft) => {
			draft.chat.currentChatRoomId = null
		})
	},
	closeChatRoom: (roomId) => {
		// Note: Use chatRoomId so we can support multiple chat rooms in the future
		set((draft) => {
			if (roomId === draft.chat.currentChatRoomId) {
				draft.chat.currentChatRoomId = null
			}
		})
	},
	createChatRoom: async (partialChatRoom) => {
		const { apiAdapter, errors: errors$, queryClient } = get()
		const playerId = get().player.id
		const player = playerId
			? queryClient.getQueryData<User>(userKeys.detail(playerId))
			: null
		if (!player) {
			errors$.next({
				error: new Error('createChatRoom: playerId not found'),
				message:
					'There was an authentication issue, try logging in again.',
			})
			return
		}

		const addChatRoomObserver = createAddChatRoomMutationObserver({
			apiAdapter,
			queryClient,
		})

		const chatRoom: Omit<ChatRoom, 'name'> & { name?: string | void } = {
			isAdmin: true,
			isArchived: false,
			lastMessage: null,
			lastReadDate: null,
			unreadMessages: [],
			...createChatRoom(
				player,
				partialChatRoom.users,
				partialChatRoom.name
			),
		}

		const result = await addChatRoomObserver.mutate(partialChatRoom)

		// NOTE: Waiting for the api result to come back before setting the
		// current chat room because, currently, the api doesn't set the id
		// to the provided value.
		set((draft) => {
			draft.chat.currentChatRoomId = result.id
		})

		createAddChatRoomOptimisticHandler(queryClient)({
			...chatRoom,
			...result,
		})

		return result
	},
	updateChatRoom: async (roomId, changes) => {
		const { apiAdapter, queryClient } = get()

		const updateChatRoomObserver = createUpdateChatRoomMutationObserver({
			apiAdapter,
			queryClient,
		})

		await updateChatRoomObserver.mutate({ roomId, changes })
	},
	openChatRoom: (roomId) => {
		set((draft) => {
			draft.chat.currentChatRoomId = roomId
		})
	},
	getRoomDraft: (roomId) => get().chat.roomDrafts[roomId] || '',
	saveRoomDraft: (roomId, message) => {
		set((draft) => {
			draft.chat.roomDrafts[roomId] = message
		})
	},
	getRoomFiles: (roomId) => get().chat.roomFiles[roomId] || [],
	saveRoomFiles: (roomId, files) => {
		set((draft) => {
			draft.chat.roomFiles[roomId] = files.map((file) => {
				if (isMobileFile(file)) {
					return file
				}
				const newFile = structuredClone(file) as FileWithId
				const url = URL.createObjectURL(file)
				newFile.id = url.replace(/.+?([\w-]+)$/, '$1')
				return newFile
			})
		})
	},
	getRoomReply: (roomId) => get().chat.roomReplies[roomId] || null,
	saveRoomReply: (roomId, message) => {
		set((draft) => {
			draft.chat.roomReplies[roomId] = message
		})
	},
	sendMessage: async (roomId, message) => {
		const { apiAdapter, queryClient } = get()

		const replyMessage = get().chat.getRoomReply(roomId)

		const chatMessage: ChatMessage = {
			id: createChatMessageId(),
			chatRoomId: roomId,
			dateCreated: formatISO(new Date()),
			files: [],
			message,
			replyToId: replyMessage ? replyMessage.id : null,
			type: 'message',
			userId: get().player.id as string,
			replyMessage,
		}

		const messageObserver = createSendMessageMutationObserver({
			apiAdapter,
			queryClient,
		})
		const messageFilesObserver = createSendMessageFilesMutationObserver({
			apiAdapter,
			queryClient,
		})
		const files = get().chat.getRoomFiles(roomId)

		// Reset message data
		set((draft) => {
			draft.chat.roomDrafts[roomId] = ''
			draft.chat.roomReplies[roomId] = null
			draft.chat.roomFiles[roomId] = []
		})

		const mutatedMessage = await messageObserver.mutate(chatMessage)
		const messageFiles = await messageFilesObserver.mutate({
			files,
			message: chatMessage,
		})

		return { ...mutatedMessage, files: messageFiles }
	},
	typing: (roomId) => {
		typing$.next(roomId)
	},
	updateLastReadDate: async (
		roomId,
		lastReadDate = formatISO(new Date())
	) => {
		const { apiAdapter, queryClient } = get()
		const lastReadDateObserver = createUpdateLastReadDateMutationObserver({
			apiAdapter,
			queryClient,
		})
		const lastReadDateMutation = await lastReadDateObserver.mutate({
			chatRoomId: roomId,
			lastReadDate,
		})
		return lastReadDateMutation
	},
})

export default createChatSlice
