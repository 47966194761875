// loosely based on brave.com

import { darken, lighten, Theme } from '@mui/material/styles'

import { createBaseTheme } from './default'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'
import {
	blendColor,
	createPaletteColor,
	createThemeShadows,
	getAlpha,
	getOverlayAlpha,
	getShade,
} from './theme-utils'

export const braveNewWorldTheme: CustomTheme = {
	name: 'braveNewWorld',
	appBackground: {
		type: 'gradient',
		colors: ['#3c0ba9', '#930fa8'],
		locations: [0.1734, 1.1319],
		angle: 121.55,
	},
	palette: {
		...defaultPalette,
		mode: 'dark',
		primary: '#9b33e6',
		background: '#3c0ba9',
		onBackground: '#fff',
		surface: `rgba(255, 255, 255, ${getOverlayAlpha(1)})`,
		onSurface: '#fff',
		sidebar: 'transparent',
		onSidebar: '#fff',
		tabBar: getAlpha(getShade('#9b33e6', 0.2), 0.8),
		onTabBar: '#fff',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'dark' } })

	const fontFamily = `Poppins, Helvetica, ui-sans-serif, ${baseMuiTheme.typography.fontFamily}`
	//const paperColor = `rgba(255, 255, 255, ${getOverlayAlpha(1)})`

	return createBaseTheme({
		name: 'braveNewWorld',
		appBackgroundImage: `linear-gradient(121.55deg,#3c0ba9 17.34%,#930fa8 113.19%)`,
		typography: {
			fontFamily,
			h1: { fontWeight: 500 },
			h2: { fontWeight: 500 },
			h3: { fontWeight: 700 },
			h4: { fontWeight: 700 },
			h5: { fontWeight: 700 },
			h6: { fontWeight: 700 },
		},
		palette: {
			mode: 'dark',
			primary: createPaletteColor(braveNewWorldTheme.palette.primary, {
				contrastText: '#fff',
			}),
			secondary: {
				main: '#fff',
			},
			translucent: {
				light: lighten(braveNewWorldTheme.palette.surface, 0.2),
				main: braveNewWorldTheme.palette.surface,
				dark: darken(braveNewWorldTheme.palette.surface, 0.2),
				contrastText: '#fff',
			},
			background: {
				contextPanel: 'rgba(255, 255, 255, 0.08)',
				default: braveNewWorldTheme.palette.background,
				paper: braveNewWorldTheme.palette.surface,
				sidebar: braveNewWorldTheme.palette.sidebar,
			},
		},
		shadows: createThemeShadows(
			blendColor(
				'#000',
				braveNewWorldTheme.palette.background,
				0.2
			).toString()
		),
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						fontWeight: 600,
					},
				},
			},

			MuiPaper: {
				styleOverrides: {
					root: ({
						ownerState,
						theme,
					}: {
						ownerState: any
						theme: Theme
					}) => ({
						...(ownerState.variant === 'elevation' && {
							boxShadow: theme.shadows[ownerState.elevation],
							backgroundColor: `rgba(255, 255, 255, 0.05)`,
							backgroundImage: 'none',
							backdropFilter: 'blur(32px)',
						}),
						...(ownerState.elevation === 0 && {
							boxShadow: 'none',
							backgroundColor: 'transparent',
							backgroundImage: 'none',
							backdropFilter: 'none',
						}),
					}),
				},
			},
		},
	})
}

export default createMuiTheme
