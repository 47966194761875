import { getDefaultPlayerOptions } from './store/player/getDefaultPlayerOptions'
import {
	BillingPlanId,
	Currency,
	PlayerOptions,
	ReactionButton,
	TaskStatusCode,
} from './types'

export const DEFAULT_PAGINATION_SIZE = 30

export const GRAVATAR_URL = 'https://secure.gravatar.com/avatar/'
export const SOURCE_TYPES = {
	TASK_DESCRIPTION: 'task.descr',
	TASK_COMMENT: 'task.comment',
	CHAT: 'chat',
} as const

export type BoardView = 'assignee' | 'owner' | 'parent' | 'status' | 'workflow'

export const BOARD_VIEW = {
	ASSIGNEE: 'assignee',
	OWNER: 'owner',
	PARENT: 'parent',
	STATUS: 'status',
	WORKFLOW: 'workflow',
} as const

export const events = {
	// Users
	ACTIVE_USERS: 'activeUsers',
	BUSY_USERS: 'busyUsers',
	USER_ADD: 'userAdd',
	USER_FOCUS: 'userFocus',
	USER_UPDATE: 'userUpdate',

	// Tasks
	TASK_ACTIVITY: 'taskActivity',
	TASK_ADDED: 'taskAdded',
	TASK_UPDATE: 'taskUpdate',
	TASKS_UPDATE: 'tasksUpdate',
	TASK_DONE: 'taskDone',
	TASK_MOVE: 'taskMove',
	UNREAD_TASKS: 'unreadTasks',
	TASK_TIMER: 'taskTimer',
	TASK_FILE_UPDATE: 'taskFileUpdate',
	TASK_ASSIGNED: 'taskAssigned',
	TASK_STATUS_UPDATE: 'taskStatusUpdate',
	TASK_START_DATE_UPDATE: 'taskStartDateUpdate',
	USER_MENTIONED_IN_TASK: 'userMentionedInTask',

	// Video call
	VIDEO_CALL: 'videoCall',

	// Others...
	INVITE_ACCEPTED: 'inviteAccepted',
	INVITE_DECLINED: 'inviteDeclined',
	SYSTEM: 'system',
	LATEST_VERSION: 'latestVersion',
	BROWSER_NOTIFICATION: 'browserNotification',
	SECTION_UPDATE: 'sectionUpdate',
	NOTIFICATION: 'notification',
	NEW_CHAT_MESSAGE: 'newChatMessage',
	CHAT_MESSAGE_UPDATE: 'chatMessageUpdate',
	CHAT_MESSAGE_DELETED: 'chatMessageDeleted',
	USER_NOTIFICATION_CREATED: 'userNotificationCreated',
	//...
	TASK_SCORE_UPDATE: 'taskscoreUpdate',
	TASK_REMINDER: 'taskReminder',
	TASK_REPEAT: 'taskRepeat',
	LEADERBOARD_UPDATE: 'leaderboardUpdate',

	CALL_USER: 'callUser',
	RECEIVE_CALL: 'receiveCall',
	CALL_REJECTED: 'callRejected',
	CALL_ACCEPTED: 'callAccepted',
	CALL_ENDED: 'callEnded',
	CALL_ADD_PEER_REQUEST: 'callAddPeerRequest',
	CALL_ADD_PEER_BROADCAST: 'callAddPeerBroadcast',
	CALL_RECEIVED: 'callReceived',
	CALL_JOIN_ROOM: 'callJoinRoom',
	CALL_ROOM_INFO: 'callRoomInfo',
	CALL_CONNECT_TO_USER: 'callConnectToUser',
	CALL_USER_CONNECTION_REQUEST: 'callUserConnectionRequest',
	CALL_LEAVE_ROOM: 'callLeaveRoom',

	UPCOMING_INVOICE: 'upcomingInvoice',
	PAYMENT_SUCCEEDED: 'paymentSucceeded',
	PAYMENT_FAILED: 'paymentFailed',
	SUBSCRIPTION_STARTED: 'subscriptionStarted',
	TRIAL_WILL_END: 'trialWillEnd',
	PAYMENT_ACTION_REQUIRED: 'paymentActionRequired',
	SUBSCRIPTION_ENDED: 'subscriptionEnded',
	BILLING_PLAN_UPDATE: 'billingPlanUpdate',
	BILLING_PLAN_UPGRADED: 'billingPlanUpgraded',

	MISSED_MESSAGES: 'missedMessages',
	FORCE_NOTIFY_MESSAGES: 'forceNotifyMessages',

	OPTIONS_CHANGED: 'optionsChanged',
	CHAT_FILE_ADDED: 'chatFileAdded',
	TYPING_IN_CHAT: 'typingInChat',
	CHAT_ROOM_CREATED: 'chatRoomCreated',
	CHAT_ROOM_REMOVED: 'chatRoomRemoved',
	CHAT_ROOM_UPDATED: 'chatRoomUpdated',
	CHAT_LAST_READ_DATE_UPDATED: 'chatLastReadDateUpdated',
	CHAT_REACTIONS: 'chatReactions',

	NEW_USER_MENTION: 'newUserMention',
} as const

// prettier-ignore
export const countryCodeCurrencyMap: Record<Currency, string[]> = {
	zar: ['za'],
	eur: ['ad','at','be','de','cy','ee','fi','fr','gr','ie','lt','lu','mt','mc','me','nl','pt','sm','sk','si','es','va'],
	gbp: ['gb'],
	usd: ['us'],
}

export interface DisplayPlan {
	prices: {
		usd: [number, number] // [month, year]
		eur: [number, number]
		gbp: [number, number]
		zar: [number, number]
	}
	tasks: number
	id: string
	name: string
	hasPlus?: boolean
	trialDays?: number
	features: string[]
}

export const plans: Record<BillingPlanId, DisplayPlan> = {
	solo: {
		prices: {
			usd: [15, 165],
			eur: [15, 165],
			gbp: [15, 165],
			zar: [100, 1100],
		},
		tasks: 50,
		id: 'solo',
		name: 'Solo',
		trialDays: 30,
		features: [
			'Unlimited Users',
			'30 Day free trial',
			'2-Year History',
			'Guest Access',
		],
	},
	pro: {
		prices: {
			usd: [100, 1100],
			eur: [100, 1100],
			gbp: [100, 1100],
			zar: [750, 8250],
		},
		tasks: 500,
		id: 'pro',
		name: 'Pro',
		trialDays: 30,
		features: [
			'Unlimited Users',
			'30 Day free trial',
			'2-Year History',
			'Guest Access',
		],
	},
	medium: {
		prices: {
			usd: [200, 2200],
			eur: [200, 2200],
			gbp: [200, 2200],
			zar: [1500, 16500],
		},
		tasks: 1500,
		id: 'medium',
		name: 'Medium',
		features: ['Unlimited Users', 'Guest access', '2-Year history'],
	},
	enterprise: {
		tasks: 3000,
		prices: {
			usd: [500, 5500],
			eur: [500, 5500],
			gbp: [500, 5500],
			zar: [2500, 27500],
		},
		id: 'enterprise',
		name: 'Enterprise',
		hasPlus: true,
		features: [
			'Unlimited Users',
			'API access',
			'Guest access',
			'5-Year history',
			'Enterprise integration',
			'Priority support',
		],
	},
} as const

export type PlayerOptionId =
	| 'isTaskEditorDetached'
	| 'minimiseProjects'
	| 'minimiseTasks'
	| 'minimiseUsers'
	| 'projects.defaultView'
	| 'projects.boardView'
	| 'projects.inactiveTasksRange'
	| 'recents.taskEdit.viewedTasks'
	| 'recents.search.queries'
	| 'taskEdit.activityTab'
	| 'tasksTableSort'
	| 'googleCalendar.syncEnabled'
	| 'googleCalendar.tytoCalendarId'
	| 'googleCalendar.channelId'
	| 'googleCalendar.resourceId'
	| 'googleCalendar.syncToken'
	| 'outlookCalendar.syncEnabled'
	| 'outlookCalendar.tytoCalendarId'
	| 'outlookCalendar.subscriptionId'
	| 'tasks.taskListView'
	| 'browserNotificationsState'
	| 'chatNotificationsState'
	| 'notificationsState' // Deprecated: use 'chatNotificationState'
	| 'recents.calls'
	| 'recents.tags'
	| 'onboarding'
	| 'ip'
	| 'starredProjects'
	| 'theme'
	| 'themeNight'
	| 'usersIsStarred'
	| 'userView.hidePinned'
	| 'userView.selectedView'
	| 'userView.taskLayout'

export const optionIds = {
	THEME: 'theme',
	THEME_NIGHT: 'themeNight',
	GOOGLE_CALENDAR_SYNC_ENABLED: 'googleCalendar.syncEnabled',
	GOOGLE_CALENDAR_TYTO_CALENDAR_ID: 'googleCalendar.tytoCalendarId',
	GOOGLE_CALENDAR_CHANNEL_ID: 'googleCalendar.channelId',
	GOOGLE_CALENDAR_RESOURCE_ID: 'googleCalendar.resourceId',
	GOOGLE_CALENDAR_SYNC_TOKEN: 'googleCalendar.syncToken',
	OUTLOOK_CALENDAR_SYNC_ENABLED: 'outlookCalendar.syncEnabled',
	OUTLOOK_CALENDAR_TYTO_CALENDAR_ID: 'outlookCalendar.tytoCalendarId',
	OUTLOOK_CALENDAR_SUBSCRIPTION_ID: 'outlookCalendar.subscriptionId',
	BROWSER_NOTIFICATIONS_STATE: 'browserNotificationsState',
	NOTIFICATIONS_STATE: 'notificationsState', // TODO: rename to CHAT_NOTIFICATIONS_STATE: 'chatNotificationsState'
	getRecentProjectsId: (userId: string) => `recents.projects.${userId}`,
	getRecentUsersId: (recentId: string) => `recents.${recentId}`,
	RECENT_CALLS: 'recents.calls',
	RECENT_TAGS: 'recents.tags',
	ONBOARDING: 'onboarding',
	USER_VIEW_HIDE_PINNED: 'userView.hidePinned',
	USER_VIEW_SELECTED_VIEW: 'userView.selectedView',
	USER_VIEW_TASK_LAYOUT: 'userView.taskLayout',
	IP: 'ip',
	STARRED_PROJECTS: 'starredProjects',
	INACTIVE_TASKS_RANGE_KEY: 'projects.inactiveTasksRange',
} as const

export const optionDefaults: Partial<PlayerOptions> = getDefaultPlayerOptions()

export const notificationStates = {
	MUTED: 'muted',
	WHEN_FREE: 'whenFree',
	ENABLED: 'enabled',
} as const

export const reactionButtons: ReactionButton[] = [
	{ id: 'love', emoji: '❤️' },
	{ id: 'like', emoji: '👍' },
	{ id: 'dislike', emoji: '👎' },
	{ id: 'funny', emoji: '😂' },
	{ id: 'surprised', emoji: '😮' },
	{ id: 'sad', emoji: '😢' },
]

export const taskRoles = {
	OWNER: 'owner',
	ASSIGNEE: 'assignee',
	FOLLOWER: 'follower',
	GUEST: 'guest',
}

export const scoreRoles = {
	ORGANISER: 'organiser',
	DOER: 'doer',
	READ_MAIL: 'read_mail',
}

export const chatMessageTypes = {
	MESSAGE: 'message',
	CALL_IN_PROGRESS: 'callInProgress',
	CALL_END: 'callEnd',
	CALL_MISSED: 'callMissed',
	ROOM_RENAMED: 'roomRenamed',
	USER_ADDED: 'userAdded',
	USER_REMOVED: 'userRemoved',
}

export const userTaskSections = {
	TODAY: 'today',
	YESTERDAY: 'yesterday',
	TOMORROW: 'tomorrow',
}

export const statusCodes = {
	NEW: 'new',
	DONE: 'done',
	DELETED: 'deleted',
	ARCHIVED: 'archived',
	WAITING: 'waiting',
	READY: 'ready',
	PLANNING: 'planning',
	STARTED: 'started',
	TESTING: 'testing',
	set: [
		'new',
		'done',
		'deleted',
		'archived',
		'waiting',
		'ready',
		'planning',
		'started',
		'testing',
	] as string[],
} as const

export const TIMER_PAUSED = 'paused'
export const TIMER_STARTED = 'started'
export const TIMER_STOPPED = 'stopped'

export const logs = {
	HEALTH_CHECK: 'health check',
	TYTO_INIT_LOG: 'tyto init',
	TASK_CREATE_LOG: 'task create',
	TASK_UPDATE_LOG: 'task_update',
	TASK_GET_LOG: 'task get',
	TASK_COMMENT_LOG: 'task comment',
} as const

export const SystemCode = {
	set: [
		'ex.start',
		'ex.done',
		'ex.diarize',
		'ex.delete',
		'ex.rename',
		'ex.start',

		'inbox',
		'ex.parent',
		'ex.drag',
	],
} as const

export const SectionSortTypes = {
	PRIORITY: 'priority',
	OLDEST: 'oldest',
	NEWEST: 'newest',
	MANUAL: 'manual',

	set: ['priority', 'oldest', 'newest', 'manual'],
}

export const Console = {
	Reset: '\x1b[0m',
	Bright: '\x1b[1m',
	Dim: '\x1b[2m',
	Underscore: '\x1b[4m',
	Blink: '\x1b[5m',
	Reverse: '\x1b[7m',
	Hidden: '\x1b[8m',

	Fg: {
		Black: '\x1b[30m',
		Red: '\x1b[31m',
		Green: '\x1b[32m',
		Yellow: '\x1b[33m',
		Blue: '\x1b[34m',
		Magenta: '\x1b[35m',
		Cyan: '\x1b[36m',
		White: '\x1b[37m',
	},

	Bg: {
		Black: '\x1b[40m',
		Red: '\x1b[41m',
		Green: '\x1b[42m',
		Yellow: '\x1b[43m',
		Blue: '\x1b[44m',
		Magenta: '\x1b[45m',
		Cyan: '\x1b[46m',
		White: '\x1b[47m',
	},
}

export const MOMENT_DATE_FORMAT = 'ddd, D MMM YYYY'
export const MOMENT_DATETIME_FORMAT = 'ddd, D MMM YYYY h:mm A'
export const DATEFNS_DATE_FORMAT = 'EEE, d MMM yyyy'
export const DATEFNS_TIME_FORMAT = 'h:mm aa'
export const DATEFNS_DATETIME_FORMAT = `${DATEFNS_DATE_FORMAT} ${DATEFNS_TIME_FORMAT}`
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSS'
export const TIME_FORMAT = 'HH:mm:ss'

// This order needs to match the crontab day order
export const DAYS = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
]

export const MONTHS = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
]

export const REPEAT_FREQ = ['day', 'week', 'month', 'year']

export const CURRENCY_SYMBOL = {
	usd: '$',
	gbp: '£',
	eur: '€',
}

export const JITSI_DOMAIN = 'call.tyto.me'

export const stopRepeatStatusCodes: TaskStatusCode[] = [
	statusCodes.DELETED,
	statusCodes.ARCHIVED,
]

export const taskFileTypes = {
	TASK: 'task',
	CHAT: 'chat',
}
