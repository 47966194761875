import { prop, uniqueBy } from 'remeda'

import Filter from '../../filters/Filter'
import { StateMutationFn } from './user-view-types'

export const addFilterMutation: (filter: Filter) => StateMutationFn =
	(filter) => (draft) => {
		const currentFilters = draft.userView.filter.selected
		const filters = uniqueBy([filter, ...currentFilters], prop('type'))
		draft.userView.filter.selected = filters
	}

export const removeFilterMutation: (filterType: string) => StateMutationFn =
	(filterType) => (draft) => {
		const currentFilters = draft.userView.filter.selected
		const filters = currentFilters.filter((f) => f.type !== filterType)
		draft.userView.filter.selected = filters
	}

export const resetFiltersMutation: StateMutationFn = (draft) => {
	draft.userView.filter.folder = []
	draft.userView.filter.owner = []
	draft.userView.filter.priority = []
	draft.userView.filter.search = []
	draft.userView.filter.selected = []
	draft.userView.filter.tags = []
	draft.userView.filter.workflow = []
}
