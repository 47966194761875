import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios'

type ApiError = {
	type: string | number
	message: string
	error: unknown
	isAxiosError: boolean
}

export interface ApiErrorNoResponse extends ApiError {
	type: 'NO_RESPONSE'
	error: AxiosError
}
export interface ApiErrorStatusCode extends ApiError {
	type: number
	error: AxiosError
}
export interface ApiErrorUnknown extends ApiError {
	type: 'UNKNOWN'
}

const createUnknownError = (err: unknown): ApiErrorUnknown => ({
	type: 'UNKNOWN',
	message: `Something went wrong. We've logged an error and will fix it ASAP.`,
	error: err,
	isAxiosError: axios.isAxiosError(err),
})

const createNoResponseError = (err: AxiosError): ApiErrorNoResponse => ({
	type: 'NO_RESPONSE',
	message: 'There was no response from the server. Please try again.',
	error: err,
	isAxiosError: true,
})

type ResponseData = {
	message: string
	statusCode: number
	error: string
}

const isObject = (obj: unknown): obj is object =>
	obj !== null && typeof obj === 'object'

const isApiResponseError = (
	response?: AxiosResponse
): response is AxiosResponse<ResponseData> => {
	return (
		typeof response !== 'undefined' &&
		typeof response.data !== 'undefined' &&
		typeof response.data.message === 'string' &&
		typeof response.data.statusCode === 'number' &&
		typeof response.data.error === 'string'
	)
}

const createStatusCodeError = (
	err: AxiosError
): ApiErrorStatusCode | ApiErrorUnknown => {
	if (isApiResponseError(err.response)) {
		return {
			type: err.response.status,
			message: err.response.data.message,
			error: err,
			isAxiosError: true,
		}
	}

	return createUnknownError(err)
}

export const isApiErrorNoResponse = (
	err: ApiError
): err is ApiErrorNoResponse =>
	err.type === 'NO_RESPONSE' && axios.isAxiosError(err)

export const isApiErrorStatusCode = (
	err: ApiError
): err is ApiErrorStatusCode =>
	typeof err.type === 'number' && axios.isAxiosError(err)

export const isApiErrorUnknown = (err: ApiError): err is ApiErrorUnknown =>
	err.type === 'UNKNOWN'

export const isApiError = (
	err: unknown
): err is ApiErrorNoResponse | ApiErrorStatusCode | ApiErrorUnknown =>
	isObject(err) && 'type' in err && 'message' in err && 'error' in err

export const apiResponseErrorHandler = async (err: unknown) => {
	if (isAxiosError(err)) {
		if (err.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			if (err.response.data) {
				throw createStatusCodeError(err)
			} else {
				throw createUnknownError(err)
			}
		} else if (err.request) {
			// The request was made but no response was received
			// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			throw createNoResponseError(err)
		} else {
			throw createUnknownError(err)
		}
	}

	// Could be anything, don't handle it
	throw createUnknownError(err)
}
