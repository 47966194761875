import { formatISO, getUnixTime, parseISO } from 'date-fns'
import { QueryFunction } from 'react-query'
import { omit, sortBy } from 'remeda'

import { FileOrMobileFile } from '../../types'
import { ApiAdapter, ApiListResult } from '../api'
import {
	chatKeys,
	ChatKeysAllMessageList,
	ChatKeysRoomsList,
	ChatKeysRoomsMessageList,
} from './chatKeys'
import { ChatMessage, ChatRoom } from './chatTypes'

export const createFetchChatAllMessagesListQueryFn =
	(
		apiAdapter: ApiAdapter
	): QueryFunction<ApiListResult<ChatMessage>, ChatKeysAllMessageList> =>
	async ({ queryKey, signal }) => {
		const [, , params] = queryKey
		return apiAdapter.chat.getAllMessages(params, { signal })
	}

export const createFetchChatRoomMessagesListQueryFn =
	(
		apiAdapter: ApiAdapter
	): QueryFunction<ApiListResult<ChatMessage>, ChatKeysRoomsMessageList> =>
	async ({ queryKey, pageParam = 1, signal }) => {
		const [, , roomId, , params] = queryKey
		return apiAdapter.chat.getRoomMessages(
			roomId,
			{ ...params, page: pageParam },
			{ signal }
		)
	}

export const createFetchChatRoomListQueryFn =
	(
		apiAdapter: ApiAdapter
	): QueryFunction<ApiListResult<ChatRoom>, ChatKeysRoomsList> =>
	async ({ signal }) =>
		apiAdapter.chat.getRooms({ signal })

export const createAddMessageReactionMutationFn =
	(apiAdapter: ApiAdapter) =>
	async ({ message, reaction }: { message: ChatMessage; reaction: string }) =>
		apiAdapter.chat.addMessageReaction(message.id, reaction)

export const createAddChatRoomMutationFn =
	(apiAdapter: ApiAdapter) =>
	async (chatRoom: { name?: string | void; users: string[] }) =>
		apiAdapter.chat.addRoom(chatRoom)

export const createUpdateChatRoomMutationFn =
	(apiAdapter: ApiAdapter) =>
	async ({
		roomId,
		changes,
	}: {
		roomId: string
		changes: { name: string; users: string[] }
	}) =>
		apiAdapter.chat.updateRoom(roomId, changes)

export const createRemoveMessageReactionMutationFn =
	(apiAdapter: ApiAdapter) =>
	async ({ message, reaction }: { message: ChatMessage; reaction: string }) =>
		apiAdapter.chat.removeMessageReaction(message.id, reaction)

export const createSendMessageMutationFn =
	(apiAdapter: ApiAdapter) => async (message: ChatMessage) => {
		const result = await apiAdapter.chat.sendMessage(
			message.chatRoomId,
			omit(message, ['replyMessage'])
		)
		return result.message
	}

export const createSendMessageFilesMutationFn =
	(apiAdapter: ApiAdapter) =>
	async ({
		files,
		message,
	}: {
		files: FileOrMobileFile[]
		message: ChatMessage
	}) =>
		apiAdapter.chat.sendMessageFiles(message.id, files)

export const getUnreadMessagesQuery = (apiAdapter: ApiAdapter) => ({
	queryKey: chatKeys.allMessagesList({ isUnread: true, pageSize: 0 }),
	queryFn: createFetchChatAllMessagesListQueryFn(apiAdapter),
})

export const getChatRoomsQuery = (apiAdapter: ApiAdapter) => ({
	queryKey: chatKeys.roomList(),
	queryFn: createFetchChatRoomListQueryFn(apiAdapter),
	select: (data: ApiListResult<ChatRoom>) => {
		if (data.items) {
			const sortedRooms = sortBy(
				data.items,
				[(room) => room.roomType === 'group', 'desc'],
				[
					(room) =>
						room.lastMessage
							? getUnixTime(
									parseISO(room.lastMessage.dateCreated)
								)
							: getUnixTime(parseISO(room.dateCreated)),
					'desc',
				]
			)
			return {
				...data,
				items: sortedRooms,
			}
		}
		return data
	},
})

export const createUpdateLastReadDateMutationFn =
	(apiAdapter: ApiAdapter) =>
	async ({
		chatRoomId,
		lastReadDate = formatISO(new Date()),
	}: {
		chatRoomId: string
		lastReadDate: string
	}) =>
		apiAdapter.chat.updateLastReadDate(chatRoomId, lastReadDate)
