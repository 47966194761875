import { isObject } from '../../type-guards';

import { ProjectListInactiveFrom } from './projects-types'

export interface ProjectListParams {
	isProject?: boolean
	inactiveFrom?: ProjectListInactiveFrom
	page?: number
	pageSize?: number
	// TODO: remove comma support in favour of arrays. This will give better
	// flexibility and better type support.
	// Comma separated list of statusCodes
	statusCodes?: string | 'all'
}
export interface ProjectDetailParams {
	inactiveFrom?: ProjectListInactiveFrom
}

export type ProjectKeysAll = ['projects']
export type ProjectKeysAllLists = ['projects', 'list']
export type ProjectKeysList = ['projects', 'list', ProjectListParams]
export type ProjectKeysAllDetails = ['projects', 'detail']
export type ProjectKeysDetail = [
	'projects',
	'detail',
	string,
	ProjectDetailParams | undefined,
]

type ProjectKeys = {
	all: ProjectKeysAll
	lists: () => ProjectKeysAllLists
	list: (params: ProjectListParams) => ProjectKeysList
	details: () => ProjectKeysAllDetails
	detail: (
		projectId: string,
		params?: ProjectDetailParams
	) => ProjectKeysDetail
}

export const isProjectListParams = (
	params: unknown
): params is ProjectListParams =>
	isObject(params) &&
	('isProject' in params ? typeof params.isProject === 'boolean' : true) &&
	('inactiveFrom' in params
		? typeof params.inactiveFrom === 'string'
		: true) &&
	('page' in params ? typeof params.page === 'number' : true) &&
	('pageSize' in params ? typeof params.pageSize === 'number' : true) &&
	('statusCodes' in params ? typeof params.statusCodes === 'string' : true)

export const projectKeys: ProjectKeys = {
	all: ['projects'],
	lists: () => [...projectKeys.all, 'list'],
	list: (params) => [...projectKeys.lists(), params],
	details: () => [...projectKeys.all, 'detail'],
	detail: (projectId, params) => [
		...projectKeys.details(),
		projectId,
		params,
	],
}
