import { useMemo } from 'react'
import { atom, useAtomValue } from 'jotai'
import { useMediaQuery } from 'usehooks-ts'

import { usePlayerOptions } from '../store/hooks'
import { themesIndex } from './theme-constants'
import { isThemeId } from './theme-guards'
import { ThemeId } from './theme-types'

export const previewThemeAtom = atom<ThemeId | null>(null)

export const usePlayerSelectedTheme = () => {
	const isDark = useMediaQuery('(prefers-color-scheme: dark)')
	const previewTheme = useAtomValue(previewThemeAtom)
	const { data = {} } = usePlayerOptions(['theme', 'themeNight'])

	const {
		theme: themeId = 'flatWhite',
		themeNight: themeNightId = 'darkMatter',
	} = data

	const lightThemeId = isThemeId(themeId) ? themeId : 'flatWhite'
	const darkThemeId = isThemeId(themeNightId) ? themeNightId : 'darkMatter'

	return useMemo(() => {
		if (previewTheme) {
			return themesIndex[previewTheme]
		} else {
			return isDark ? themesIndex[darkThemeId] : themesIndex[lightThemeId]
		}
	}, [darkThemeId, lightThemeId, isDark, previewTheme])
}
