import { alpha, lighten } from '@mui/material/styles'

import darkMatter from './darkMatter'
import { createBaseTheme } from './default'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'

export const blueSteelTheme: CustomTheme = {
	name: 'blueSteel',
	palette: {
		...defaultPalette,
		mode: 'dark',
		background: '#0f1724',
		onBackground: '#fff',
		surface: '#1d2636',
		onSurface: '#fff',
		sidebar: '#1d2636',
		onSidebar: '#fff',
	},
}

// based on https://openbase.io/js/@beeman/micro-unfurl
const createMuiTheme = () => {
	const darkMatterMuiTheme = darkMatter()

	return createBaseTheme({
		name: 'blueSteel',
		palette: {
			mode: 'dark',
			//primary: baseTheme.palette.primary,
			//secondary: baseTheme.palette.secondary,
			background: {
				default: blueSteelTheme.palette.background,
				paper: blueSteelTheme.palette.surface,
				contextPanel: blueSteelTheme.palette.surface,
				sidebar: blueSteelTheme.palette.sidebar,
			},
			translucent: {
				main: alpha(lighten(blueSteelTheme.palette.surface, 0.25), 0.5),
				dark: alpha(lighten(blueSteelTheme.palette.surface, 0.1), 0.5),
				contrastText: '#fff',
			},
		},

		components: {
			MuiAppBar: {
				styleOverrides: {
					colorDefault: {
						backgroundColor: blueSteelTheme.palette.surface,
						color: darkMatterMuiTheme.palette.text.primary,
					},
				},
			},
		},
	})
}

export default createMuiTheme
