/*
 * Task factory functions for mutating state
 */

import { formatISO } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import { QueryClient } from 'react-query'
import { isNot } from 'remeda'

import {
	createTaskActivityDone,
	createWrappedActivity,
} from '../../../task-activity'
import { parseTaskDoneToString } from '../../../task-activity/parsers'
import { Task, User } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { taskKeys, userKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createAddTaskActivityToQueryCache } from '../../task-activity/taskActivityMutations'
import { createUpdateTaskOnQueryCache } from './update'

type CommentData = {
	draftModel: RawDraftContentState
	html: string
}

export const markAsDoneMutation = async (
	state: AppState,
	taskId: string,
	commentData: CommentData
) => {
	const { apiAdapter, queryClient, player } = state
	if (player.id !== null) {
		createMarkAsDoneOnQueryCache(apiAdapter, queryClient)(
			player.id,
			taskId,
			commentData
		)
	}
	return createMarkAsDoneOnApi(apiAdapter)(taskId, commentData)
}

export const createMarkAsDoneOnApi =
	(apiAdapter: ApiAdapter) =>
	async (taskId: string, commentData: CommentData) =>
		apiAdapter.tasks.markAsDone(taskId, commentData)

export const createMarkAsDoneOnQueryCache =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (userId: string, taskId: string, commentData: CommentData) => {
		const updateQueryCacheMutation =
			createUpdateTaskOnQueryCache(queryClient)

		await updateQueryCacheMutation(taskId, {
			doneDate: formatISO(Date.now()),
			statusCode: 'done',
		})

		// Remove the inactive task from the parent childSortOrder
		const currentTask = queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (currentTask && currentTask.parentId) {
			const parentTask = queryClient.getQueryData<Task>(
				taskKeys.detail(currentTask.parentId)
			)

			if (parentTask) {
				const newChildSortOrder = (
					parentTask.childSortOrder || []
				).filter(isNot((id) => id === taskId))
				await updateQueryCacheMutation(currentTask.parentId, {
					childSortOrder: newChildSortOrder,
				})
			}
		}

		const user = queryClient.getQueryData<User>(userKeys.detail(userId))
		if (user && currentTask) {
			const activity = createTaskActivityDone(
				user,
				commentData.html,
				commentData.draftModel
			)
			const wrappedActivity = createWrappedActivity(
				user.id,
				currentTask.id,
				parseTaskDoneToString(activity),
				activity
			)
			const addTaskActivityToQueryCache =
				createAddTaskActivityToQueryCache(apiAdapter, queryClient)
			await addTaskActivityToQueryCache(wrappedActivity)
		}
	}
