import { ChatAllMessagesParams, ChatRoomMessagesParams } from './chatTypes'

export type ChatKeysAll = ['chat']
export type ChatKeysAllMessageList = ['chat', 'messages', ChatAllMessagesParams]
export type ChatKeysRooms = ['chat', 'rooms']
export type ChatKeysRoomsDetail = ['chat', 'rooms', 'detail', string]
export type ChatKeysRoomsList = ['chat', 'rooms', 'list']
export type ChatKeysRoomsMessageList =
	| ['chat', 'rooms', string, 'messages']
	| ['chat', 'rooms', string, 'messages', ChatRoomMessagesParams]

type ChatKeys = {
	all: ChatKeysAll
	allMessagesList: (params: ChatAllMessagesParams) => ChatKeysAllMessageList
	rooms: () => ChatKeysRooms
	roomDetail: (roomId: string) => ChatKeysRoomsDetail
	roomList: () => ChatKeysRoomsList
	roomMessagesList: (
		roomId: string,
		params?: ChatRoomMessagesParams
	) => ChatKeysRoomsMessageList
}

export const chatKeys: ChatKeys = {
	all: ['chat'],
	allMessagesList: (params) => [...chatKeys.all, 'messages', params],
	rooms: () => [...chatKeys.all, 'rooms'],
	roomDetail: (roomId) => [...chatKeys.rooms(), 'detail', roomId],
	roomList: () => [...chatKeys.rooms(), 'list'],
	roomMessagesList: (roomId, params) =>
		params
			? [...chatKeys.rooms(), roomId, 'messages' as const, params]
			: [...chatKeys.rooms(), roomId, 'messages' as const],
}
