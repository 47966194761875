import { alpha } from '@mui/material/styles'

import { createWallpaperTheme } from './default'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'
import { createThemeShadows } from './theme-utils'
import snowBackground from './wallpapers/background-snow.avif'

export const snowTheme: CustomTheme = {
	name: 'snow',
	appBackground: { type: 'image', url: snowBackground },
	palette: {
		...defaultPalette,
		background: '#d0e5e8',
		surface: '#E8EFF0',
		shadow: '#195e66',
		sidebar: 'rgba(255, 255, 255, 0.3)',
	},
}

const createMuiTheme = () => {
	return createWallpaperTheme(
		{
			name: 'snow',
			appBackgroundImage: `url("${snowBackground}")`,
			palette: {
				background: {
					default: snowTheme.palette.background,
					paper: snowTheme.palette.surface,
					sidebar: snowTheme.palette.sidebar,
				},
			},
			shadows: createThemeShadows(snowTheme.palette.shadow),
			components: {
				MuiBackdrop: {
					styleOverrides: {
						root: {
							'&:not(.MuiBackdrop-invisible)': {
								backgroundColor: alpha(
									snowTheme.palette.shadow,
									0.2
								),
							},
						},
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							backdropFilter: 'blur(6px)',
						},
					},
				},
				TyTabBar: {
					styleOverrides: {
						root: {
							backgroundColor: snowTheme.palette.sidebar,
						},
					},
				},
				TyTabBarItem: {
					styleOverrides: {
						selected: {
							backgroundColor: snowTheme.palette.surface,
						},
					},
				},
			},
		},
		{}
	)
}

export default createMuiTheme
