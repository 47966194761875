import {
	TaskActivity,
	TaskActivityWorkflow,
	TaskActivityWorkflowSchema,
} from '../task-activity-schemas'
import {
	getWorkflowActivityType,
	WORKFLOW_ACTIVITY_TYPE,
} from '../task-activity-utils'
import { formatRequirementsChangeToHtml } from '../workflow/formatRequirementsChange'

export const parseTaskWorkflowToString = (
	activity: TaskActivityWorkflow
): string => {
	const meta = activity.meta

	const hasComment =
		Boolean(activity.comment) &&
		// If there is a space or the comment is empty, don't show it.
		activity.comment !== '<p><br></p>' &&
		activity.comment !== '<p>&nbsp;</p>'

	const comment = hasComment ? ` with comment:<br/>${activity.comment}` : '.'

	const workflowActivityType = getWorkflowActivityType(activity)
	const { ADD, DONE, MOVE, REMOVE, REQUIREMENTS_CHANGE } =
		WORKFLOW_ACTIVITY_TYPE

	switch (workflowActivityType) {
		case ADD:
			return `${meta.user.nickname} added workflow${
				activity.workflowTitle
					? ` <strong>${activity.workflowTitle}</strong>`
					: ''
			}.`
		case DONE: {
			const stepNumber = (activity.oldStepIndex || 0) + 1
			return `${meta.user.nickname} completed <strong>step ${stepNumber}</strong>${comment}`
		}
		case MOVE: {
			if (
				activity.newStepIndex === null ||
				activity.oldStepIndex === null
			) {
				return ''
			}
			const skipped = activity.newStepIndex - activity.oldStepIndex > 1
			const verb = skipped
				? 'skipped'
				: activity.newStepIndex > activity.oldStepIndex
					? 'progressed'
					: 'regressed'
			const newStepNumber = activity.newStepIndex + 1

			return `${meta.user.nickname} ${verb} to <strong>step ${newStepNumber}</strong>${comment}`
		}
		case REMOVE:
			return `${meta.user.nickname} removed workflow${
				activity.removedWorkflowTitle
					? ` <strong>${activity.removedWorkflowTitle}</strong>`
					: ''
			}.`
		case REQUIREMENTS_CHANGE:
			return formatRequirementsChangeToHtml(meta.user, activity)

		default:
			return `${meta.user.nickname} made a change to workflow`
	}
}

export const validateTaskWorkflow = (taskActivity: TaskActivity) =>
	TaskActivityWorkflowSchema.safeParse(taskActivity)
