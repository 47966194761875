import { alpha, emphasize } from '@mui/material/styles'

import { createBaseTheme, createWallpaperTheme } from './default'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'
import { createPaletteColor } from './theme-utils'
import whimsyBackground from './wallpapers/background-whimsy.svg'

export const whimsyTheme: CustomTheme = {
	name: 'whimsy',
	appBackground: { type: 'svg', svg: whimsyBackground },
	borderRadius: 12,
	palette: {
		...defaultPalette,
		primary: '#5D5CF1',
		background: '#27273b',
		onBackground: '#fff',
		sidebar: 'rgba(25, 27, 41, 0.95)',
		onSidebar: '#fff',
	},
}

//source https://bashooka.com/coding/web-background-animation-effects/
const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme()

	return createWallpaperTheme(
		{
			name: 'whimsy',
			appBackgroundImage: `url("${whimsyBackground}")`,
			palette: {
				mode: 'light',
				primary: createPaletteColor(whimsyTheme.palette.primary),
				background: {
					default: whimsyTheme.palette.background,
					contextPanel: emphasize(
						alpha(baseMuiTheme.palette.background.paper, 0.075),
						1
					),
					sidebar: whimsyTheme.palette.sidebar,
				},
			},
			shape: {
				borderRadius: whimsyTheme.borderRadius,
			},
			components: {
				TyTabBar: {
					styleOverrides: {
						root: {
							backgroundColor: whimsyTheme.palette.sidebar,
						},
					},
				},
				TyTabBarItem: {
					styleOverrides: {
						selected: {
							backgroundColor: whimsyTheme.palette.background,
						},
					},
				},
			},
		},
		{ palette: { mode: 'dark' } }
	)
}

export default createMuiTheme
