import { alpha, darken } from '@mui/material/styles'

import { createBaseTheme, defaultBorderRadius } from './default'
import { defaultPalette } from './palettes'
import type { CustomTheme } from './theme-types'

export const flatWhiteTheme: CustomTheme = {
	name: 'flatWhite',
	borderRadius: defaultBorderRadius,
	palette: {
		...defaultPalette,
		background: 'rgb(244, 247, 250)',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'light' } })

	return createBaseTheme({
		name: 'flatWhite',
		palette: {
			background: {
				default: flatWhiteTheme.palette.background,
				tertiary: baseMuiTheme.palette.grey[200],
			},
		},

		components: {
			MuiPaper: {
				styleOverrides: {
					elevation1: {
						boxShadow: 'none',
					},
					elevation2: {
						border: `1px solid ${alpha(
							baseMuiTheme.palette.divider,
							0.06
						)}`,
						boxShadow: 'none',
					},
				},
			},
			TyTabBar: {
				styleOverrides: {
					root: {
						backgroundColor: baseMuiTheme.palette.background.paper,
					},
				},
			},
			TyTabBarItem: {
				styleOverrides: {
					root: {
						backgroundColor: alpha(
							darken(flatWhiteTheme.palette.background, 0.2),
							0.2
						),
					},
					selected: {
						backgroundColor: flatWhiteTheme.palette.background,
					},
				},
			},
		},
	})
}

export default createMuiTheme
