import { z } from 'zod'

import { RawDraftContentStateSchema } from '../draft-js-content-state-schema'

export const WorkflowStepActionUpdateTaskSchema = z.object({
	type: z.literal('updateTask'),
	payload: z.object({
		assigneeId: z.string().nullable().optional(),
		duration: z.number().optional(),
	}),
})
export type WorkflowStepActionUpdateTask = z.infer<
	typeof WorkflowStepActionUpdateTaskSchema
>

export const WorkflowStepActionSchema = z.discriminatedUnion('type', [
	WorkflowStepActionUpdateTaskSchema,
])
export type WorkflowStepAction = z.infer<typeof WorkflowStepActionSchema>

export const WorkflowStepSchema = z.object({
	id: z.string(),
	title: z.string(),
	description: z.string().optional(),
	duration: z.number().optional(),
	actions: z.array(WorkflowStepActionSchema).optional(),
	requirements: RawDraftContentStateSchema.optional(),
})
export type WorkflowStep = z.infer<typeof WorkflowStepSchema>
