import { useEffect, useState } from 'react'
import {
	QueryObserverOptions,
	useInfiniteQuery,
	useQueries,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query'
import { isDeepEqual, isTruthy, pick, prop, sortBy } from 'remeda'
import { debounceTime, Subject } from 'rxjs'

import { prepareTaskForHash } from '../../data-hash/utils'
import { getHash } from '../../sync'
import { Attachment, Task } from '../../types'
import { ApiTaskListResult } from '../api/baseApiAdapter'
import { fileKeys } from '../files/file-keys'
import {
	createFetchTaskListQueryFn,
	createTaskDetailQuery,
	taskKeys,
	TaskKeysDetail,
} from '../queries'
import useStore from '../useStore'
import { WatchableOptions } from './types'

interface UseTaskInDateRangeProps {
	assigneeId?: string | null
	startDateFrom: string
	startDateUntil: string
}

const ONE_HOUR = 1000 * 60 * 60
const ONE_WORK_DAY = 1000 * 60 * 60 * 8

const fileQueryConfig = {
	cacheTime: ONE_WORK_DAY,
	staleTime: ONE_HOUR,
}

export const useTask = (
	taskId: string,
	{ watchFields, ...options }: WatchableOptions<Task> = {}
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const [hash, setHash] = useState<string>()

	const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)
	const params = hash ? { h: hash } : undefined

	useEffect(() => {
		if (!taskId) {
			return
		}

		const queryCache = queryClient.getQueryCache()
		return queryCache.subscribe((event) => {
			if (
				!event ||
				!isDeepEqual(event.query.queryKey, taskKeys.detail(taskId))
			) {
				return
			}

			switch (event.type) {
				case 'queryUpdated': {
					const currentTask = queryClient.getQueryData<
						Task & { hash?: string }
					>(taskKeys.detail(taskId))

					if (!currentTask) {
						return
					}

					if (currentTask.hash) {
						setHash(currentTask.hash)
					} else {
						setHash(getHash(prepareTaskForHash(currentTask)))
					}
					break
				}
			}
		})
	}, [queryClient, taskId])

	const { queryKey, queryFn, ...other } = taskDetailQuery(taskId, params)
	return useQuery<Task, unknown, Task, TaskKeysDetail>({
		queryKey,
		queryFn,
		...other,
		select: watchFields
			? (data) => data && pick(data, watchFields)
			: undefined,
		...options,
	})
}

export const useTasks = (taskIds: string[], options = {}) => {
	const { apiAdapter, queryClient } = useStore.getState()

	const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)

	return useQueries(
		taskIds.map((taskId) => ({
			...taskDetailQuery(taskId),
			...options,
			enabled: Boolean(taskId),
		}))
	)
}

export const useTasksInDateRange = <
	TData = ApiTaskListResult<string>,
	TError = unknown,
>(
	{ assigneeId, startDateFrom, startDateUntil }: UseTaskInDateRangeProps,
	queryOptions: Omit<
		UseQueryOptions<ApiTaskListResult<string>, TError, TData>,
		'queryKey' | 'queryFn'
	>
): UseQueryResult<TData, TError> => {
	const { apiAdapter, queryClient } = useStore.getState()

	return useQuery<ApiTaskListResult<string>, TError, TData>(
		taskKeys.list({ assigneeId, startDateFrom, startDateUntil }),
		createFetchTaskListQueryFn(apiAdapter, queryClient),
		queryOptions
	)
}

export const sortTaskFiles = sortBy<Attachment[]>([prop('lastUpdated'), 'asc'])
export const useFiles = (
	taskId: string,
	config?: Pick<QueryObserverOptions, 'staleTime'>
) =>
	useQuery(
		taskKeys.filesList(taskId),
		async ({ queryKey, signal }) => {
			const taskId = queryKey[2]
			if (!taskId) return []

			const { apiAdapter, queryClient } = useStore.getState()
			const response = await apiAdapter.tasks.getFiles(taskId, { signal })

			const files = response.filter(isTruthy)
			files.forEach((file) =>
				queryClient.setQueryData(fileKeys.detail(file.id), file)
			)
			return files
		},
		{
			select: (files) => sortTaskFiles(files.filter(isTruthy)),
			...fileQueryConfig,
			...config,
		}
	)

const debounceSubject$ = new Subject<string>()
const debounce$ = debounceSubject$.pipe(debounceTime(250))

export const useSearchResults = (searchQuery: string, options = {}) => {
	const [query, setQuery] = useState(searchQuery)
	const { apiAdapter } = useStore.getState()

	useEffect(() => {
		debounceSubject$.next(searchQuery)
	}, [searchQuery])

	useEffect(() => {
		const subscription = debounce$.subscribe((query) => {
			setQuery(query)
		})
		return () => subscription.unsubscribe()
	}, [])

	return useInfiniteQuery(
		[
			'tasks',
			{
				search: query,
				statusCodes: 'all',
				orderBy: '-isActive,-dateCreated',
				pageSize: 15,
			},
		],
		async ({ queryKey, pageParam = 1, signal }) => {
			const promise = apiAdapter.tasks.getList(
				{
					...(typeof queryKey[1] === 'object' ? queryKey[1] : {}),
					page: pageParam,
				},
				{ signal }
			)

			const result = await promise
			return result
		},
		{
			enabled: Boolean(query),
			getNextPageParam: (lastPage) =>
				lastPage?.hasMore ? lastPage.page + 1 : undefined,
			getPreviousPageParam: (firstPage) =>
				firstPage?.page === 1 ? undefined : firstPage.page - 1,
			...options,
		}
	)
}
