import { filter, isNot, map, pipe, prop, sortBy } from 'remeda'

import { isInactive } from '../../helpers/taskStatus'
import { Task } from '../../types'

// Remove inactive tasks from the list and sort by the given childSortOrder.
// Return only the task ids.
export const filterAndSortSubtasksToIds = (
	childSortOrder: string[],
	subtasks: Pick<Task, 'id' | 'statusCode'>[]
): string[] =>
	pipe(
		subtasks,
		filter(isNot((task) => isInactive(task.statusCode))),
		sortBy((task) => childSortOrder.indexOf(task.id)),
		map(prop('id'))
	)
