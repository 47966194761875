import { produce } from 'immer'
import {
	MutateOptions,
	MutationObserver,
	notifyManager,
	type MutationObserverOptions,
	type MutationObserverResult,
	type QueryClient,
} from 'react-query'
import { Observable } from 'rxjs'

import { noop } from '@tyto/utils'

import type { Task, TaskPosition } from '../../../types'
import type { ApiAdapter, ApiResult } from '../../api/baseApiAdapter'
import { taskKeys } from '../../queries/tasks'
import type { AppState } from '../../store-types'
import { taskActivityKeys } from '../../task-activity'
import type { TaskActivityQueryData } from '../../task-activity/task-activity-types'
import { createEmptyTaskActivityQueryData } from '../../task-activity/task-activity-utils'
import { getParentsByParent } from '../../utils'
import { createBaseQueryCacheMutation } from '../baseCreate'

// Using MutationObserver to stay agnostic:
// https://github.com/TanStack/query/blob/v3.39.1/src/react/useMutation.ts#L88
export const createMutationObservable = <
	TData = unknown,
	TError = unknown,
	TVariables = void,
	TContext = unknown,
>(
	queryClient: QueryClient,
	options: MutationObserverOptions<TData, TError, TVariables, TContext>
): Observable<MutationObserverResult<TData, TError, TVariables, TContext>> =>
	new Observable((observer) => {
		const defaultedOptions = queryClient.defaultMutationOptions(options)

		// Create mutation observer
		const mutationObserver = new MutationObserver(
			queryClient,
			defaultedOptions
		)

		observer.next(mutationObserver.getCurrentResult())

		return mutationObserver.subscribe(
			notifyManager.batchCalls(
				(
					result: MutationObserverResult<
						TData,
						TError,
						TVariables,
						TContext
					>
				) => observer.next(result)
			)
		)
	})

/*
 * Task factory functions for mutating state
 */
export const addTaskMutation = (
	state: AppState,
	newTask: Task,
	position?: TaskPosition
): Observable<MutationObserverResult<ApiResult<Task>>> => {
	const { apiAdapter, queryClient } = state
	// const addTaskToApi = createAddTaskToApi(apiAdapter)
	// createAddTaskToQueryCache(queryClient)(newTask, position)
	// return addTaskToApi(newTask, position)
	return createMutationObservable<ApiResult<Task>>(queryClient, {
		mutationKey: taskKeys.detail(newTask.id),
		mutationFn: async () =>
			createAddTaskToApi(apiAdapter)(newTask, position),
		onMutate: async () =>
			createAddTaskToQueryCache(queryClient)(newTask, position),
	})
}

export const createAddTaskToApi =
	(apiAdapter: ApiAdapter) =>
	async (
		newTask: { title: string } & Partial<Task>,
		position?: TaskPosition
	) =>
		apiAdapter.tasks.add(newTask, position)

export const createAddTaskToQueryCache =
	(queryClient: QueryClient) =>
	async (newTask: Task, position?: TaskPosition) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)

		// Update the parent if the task was created in a parent
		if (position && position.parentId && position.index) {
			standardMutation<Task>(
				taskKeys.detail(position.parentId),
				(prevData: Task | undefined) => {
					if (!prevData) {
						return undefined
					}

					return produce(prevData, (draft) => {
						if (!draft.childSortOrder || !position.index) {
							return
						}

						if (draft.childSortOrder.includes(newTask.id)) {
							draft.childSortOrder.splice(
								draft.childSortOrder.indexOf(newTask.id),
								1
							)
						}

						draft.childSortOrder.splice(
							position.index,
							0,
							newTask.id
						)
					})
				}
			)
		}

		// See if we can fill in the parents.
		if (!newTask.parents && newTask.parentId) {
			const parent = queryClient.getQueryData<Task>(
				taskKeys.detail(newTask.parentId)
			)
			newTask.parents = getParentsByParent(parent)
		}

		// TODO: add reminder, files, time, activity
		// Set initial data for task-edit
		queryClient.setQueryData(taskKeys.remindersList(newTask.id), [])
		queryClient.setQueryData(taskKeys.filesList(newTask.id), [])
		queryClient.setQueryData(taskKeys.detailTimeList(newTask.id), [])
		queryClient.setQueryData<TaskActivityQueryData>(
			taskActivityKeys.list(newTask.id),
			createEmptyTaskActivityQueryData()
		)

		return standardMutation<Task>(taskKeys.detail(newTask.id), newTask)
	}
