import type { CustomPalette } from './theme-types'

export const defaultPalette: CustomPalette = {
	mode: 'light',

	primary: '#236af7',
	onPrimary: '#fff',
	secondary: '#1a1a1a',
	onSecondary: '#fff',
	neutral: '#ebebeb',
	onNeutral: '#1a1a1a',
	error: '#ef5350',
	onError: '#fff',
	background: '#fff',
	onBackground: '#1a1a1a',
	surface: '#fff',
	onSurface: '#1a1a1a',
	outline: '#ebebeb',
	shadow: '#000',
	scrim: '#000',

	brand: '#f72368',
	onBrand: '#fff',
	//busy: '#029cfd',
	busy: '#4441fb',
	onBusy: '#fff',
	bulk: '#40acf9',
	onBulk: '#fff',
	archived: '#f89500',
	onArchived: '#fff',
	deleted: '#eb4652',
	onDeleted: '#fff',
	done: '#49c666',
	onDone: '#fff',
	duration: '#aaa',
	onDuration: '#fff',
	overtime: '#d32f2f',
	onOvertime: '#fff',
	starred: '#f0c30e',
	onStarred: '#fff',
	userFocus: '#e9d039',
	onUserFocus: '#fff',
	workflow: '#59C1C7',
	onWorkflow: '#fff',

	// exact color
	scrorpion: '#575757',

	link: '#40acf9',
	onLink: '#fff',
	sidebar: '#fff',
	onSidebar: '#1a1a1a',

	textAlpha1: 0.87,
	textAlpha2: 0.5,
	textAlpha3: 0.4,
}
