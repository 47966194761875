import {
	compose,
	countBy,
	identity,
	indexBy,
	isEmpty,
	isNil,
	negate,
	sortBy,
} from 'ramda'
import {
	entries,
	filter,
	flat,
	isArray,
	isNot,
	map,
	pipe,
	prop,
	reduce,
} from 'remeda'

import { getShortText } from '../helpers/priority'
import { Task, TaskParent } from '../types'
import Filter from './Filter'
import {
	AncestorCriteria,
	AssigneeCriteria,
	OwnerCriteria,
	PriorityCriteria,
	StatusCriteria,
	TagsCriteria,
	WorkflowCriteria,
} from './taskCriteria'

type TaskAssigneeFields = Pick<Task, 'assigneeId'>
type TaskFoldersFields = Pick<Task, 'parents'>
type TaskOwnerFields = Pick<Task, 'ownerId'>
type TaskPriorityFields = Pick<Task, 'importance' | 'urgency'>
type TaskStatusFields = Pick<Task, 'statusCode'>
type TaskTagsFields = Pick<Task, 'tags'>
type TaskWorkflowFields = Pick<Task, 'id' | 'parentId' | 'workflowData'>

export const mapTasksToAssigneeFilters = (
	tasks: TaskAssigneeFields[]
): Filter[] =>
	pipe(
		tasks,
		reduce(
			(acc, task) => {
				const key = task.assigneeId ?? 'null'
				if (!acc[key]) {
					acc[key] = 0
				}
				acc[key] += 1

				return acc
			},
			{} as Record<string, number>
		),
		entries,
		map(
			([assigneeId, count]) =>
				new Filter('assignee', {
					criteria: new AssigneeCriteria([
						assigneeId === 'null' ? null : assigneeId,
					]),
					count: count as number,
					id: assigneeId === 'null' ? 'unassigned' : assigneeId,
				})
		),
		sortBy<Filter>(compose(negate, prop('count')))
	)

export const mapTasksToAncestorFilters = (
	tasks: TaskFoldersFields[]
): Filter[] => {
	const buildFilters = (
		tasks: TaskFoldersFields[],
		parentId?: string | null,
		level = 0
	) =>
		pipe(
			tasks,
			filter(
				(task: { parents: TaskParent[] }) =>
					task.parents?.length > level &&
					(parentId ? task.parents[0].id === parentId : true)
			),
			map((task) => task.parents[level]),
			countBy(prop('id')),
			entries,
			map(
				([ancestorId, count]) =>
					new Filter('ancestor', {
						criteria: new AncestorCriteria([ancestorId]),
						count: count as number,
						id: ancestorId,
					})
			),
			sortBy<Filter>(compose(negate, prop('count')))
		)

	const topLevelFilters = buildFilters(tasks)
	return reduce<Filter, Filter[]>(
		topLevelFilters,
		(acc, filter) => {
			const childrenFilters = buildFilters(tasks, filter.id, 1)
			acc.push(filter)
			if (childrenFilters.length > 0) {
				return [...acc, ...childrenFilters]
			}
			return acc
		},
		[]
	)
}

export const mapTasksToOwnerFilters = (tasks: TaskOwnerFields[]): Filter[] =>
	pipe(
		tasks,
		filter(isNot(({ ownerId }) => isEmpty(ownerId) || isNil(ownerId))),
		countBy(prop('ownerId')),
		entries,
		map(
			([ownerId, count]) =>
				new Filter('owner', {
					criteria: new OwnerCriteria([ownerId]),
					count: count as number,
					id: ownerId,
				})
		),
		sortBy<Filter>(compose(negate, prop('count')))
	)

export const mapTasksToPriorityFilters = (
	tasks: TaskPriorityFields[]
): Filter[] =>
	pipe(
		tasks,
		map(getShortText),
		filter(isNot(isEmpty)),
		countBy(identity),
		entries,
		map(
			([priorityKey, count]) =>
				new Filter('priority', {
					criteria: new PriorityCriteria([priorityKey]),
					count: count as number,
					id: priorityKey,
				})
		),
		sortBy<Filter>(compose(negate, prop('count')))
	)

export const mapTasksToStatusFilters = (
	tasks: TaskStatusFields[]
): Filter[] => {
	const rejected = (tasks.filter(isNot( isEmpty)))
	const countIndex = countBy(prop('statusCode'), rejected)
	const pairs = entries(countIndex)
	const filters = map(
		pairs,
		([statusCode, count]) =>
			new Filter('status', {
				criteria: new StatusCriteria([statusCode]),
				count: count as number,
				id: statusCode,
			})
	)
	return sortBy<Filter>(compose(negate, prop('count')), filters)
}

export const mapTasksToTagsFilters = (tasks: TaskTagsFields[]): Filter[] =>
	pipe(
		tasks,
		map((task) => (task && isArray(task.tags) ? task.tags : [])),
		filter((tags) => tags.length > 0),
		flat,
		reduce(
			(acc, tag: string) => {
				const lowerCaseTag = tag.toLowerCase()
				if (lowerCaseTag) {
					if (!acc[lowerCaseTag]) {
						acc[lowerCaseTag] = 0
					}
					acc[lowerCaseTag] += 1
				}
				return acc
			},
			{} as Record<string, number>
		),
		entries,
		map(([tagName, count]) => {
			const lowerCaseTagName = tagName.toLowerCase()
			return new Filter('tags', {
				criteria: new TagsCriteria([lowerCaseTagName]),
				count: count as number,
				id: lowerCaseTagName,
			})
		}),
		sortBy<Filter>(compose(negate, prop('count')))
	)

export const mapTasksToWorkflowFilters = (
	tasks: TaskWorkflowFields[]
): Filter[] => {
	const taskById = indexBy(prop('id'), tasks)
	return pipe(
		tasks,
		filter(isNot(isEmpty)),
		map((task) => {
			let workflowId = task?.workflowData?.id

			if (!workflowId && task.parentId !== null) {
				const parent = taskById[task.parentId]
				workflowId = parent?.workflowData?.childId
			}

			return workflowId || 'noWorkflow'
		}),
		countBy(identity),
		entries,
		map(
			([workflowId, count]) =>
				new Filter('workflow', {
					criteria: new WorkflowCriteria(workflowId),
					count: count as number,
					id: workflowId,
				})
		),
		sortBy<Filter>(compose(negate, prop('count')))
	)
}
