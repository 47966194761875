import remove from 'lodash/remove'

import ApiAdapter from '../common/api-adapter'
import Event from '../common/event/Event'

const state = {}

const TaskStore = (ApiAdapter) =>
	Object.assign(new Event(), {
		deleteFile(taskId, fileId) {
			return ApiAdapter.remove(
				'/tasks/' + taskId + '/files/' + fileId
			).catch((e) => {
				console.error(
					'TaskStore.deleteFile: failed to delete file for id(' +
						taskId +
						')',
					e
				)
			})
		},
		putTeamMemberInvite(taskId, teamMember) {
			// Note: Helper function to be used until we can sync tasks.

			const {
				email,
				name = teamMember.email,
				role = 'follower',
				inviteType = 'guest',
			} = teamMember

			return ApiAdapter.post('/tasks/' + taskId + '/linkUser', {
				name,
				role,
				email,
				inviteType,
			}).catch((e) => {
				console.error(
					'TaskStore.putTeamMember: failed to save task for id(' +
						taskId +
						')',
					e
				)
			})
		},
		removeFile(taskId, fileId) {
			const task = state[taskId]

			if (task) {
				const changes = {
					sharedFiles: [].concat(task.sharedFiles || []),
				}
				remove(changes.sharedFiles, (f) => f.id === fileId)

				this.setTask(taskId, changes)
			}
		},
	})
const Store = TaskStore(ApiAdapter)

export default Store
