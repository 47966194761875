import babyBlue, { babyBlueTheme } from './babyBlue'
import blueSteel, { blueSteelTheme } from './blueSteel'
import braveNewWorld, { braveNewWorldTheme } from './braveNewWorld'
//import brettWasHere from './brettWasHere'
import city, { cityTheme } from './city'
import darkMatter, { darkMatterTheme } from './darkMatter'
import { createBaseTheme } from './default'
import flatWhite, { flatWhiteTheme } from './flatWhite'
import midnight, { midnightTheme } from './midnight'
import minimalist, { minimalistTheme } from './minimalist'
//import playful from './playful'
import rubyTuesday, { rubyTuesdayTheme } from './rubyTuesday'
import snow, { snowTheme } from './snow'
import { ThemeData, ThemeId } from './theme-types'
import whimsy, { whimsyTheme } from './whimsy'
import woodDark, { woodDarkTheme } from './woodDark'

const babyBlueMuiTheme = babyBlue()
const blueSteelMuiTheme = blueSteel()
const braveNewWorldMuiTheme = braveNewWorld()
//const brettWasHereMuiTheme = brettWasHere()
const darkMatterMuiTheme = darkMatter()
const flatWhiteMuiTheme = flatWhite()
const midnightMuiTheme = midnight()
const minimalistMuiTheme = minimalist()
//const playfulMuiTheme = playful()
const cityMuiTheme = city()
const rubyTuesdayMuiTheme = rubyTuesday()
const snowMuiTheme = snow()
const whimsyMuiTheme = whimsy()
const woodDarkMuiTheme = woodDark()

export const defaultTheme = createBaseTheme()
export const currentTheme = flatWhiteMuiTheme
export const backgroundTheme = flatWhiteMuiTheme
export const inverseTheme = darkMatterMuiTheme

export const themesIndex: Record<ThemeId, ThemeData> = {
	darkMatter: {
		id: 'darkMatter',
		muiTheme: darkMatterMuiTheme,
		name: 'Dark matter',
		theme: darkMatterTheme,
	},
	flatWhite: {
		id: 'flatWhite',
		muiTheme: flatWhiteMuiTheme,
		name: 'Flat white',
		theme: flatWhiteTheme,
	},
	minimalist: {
		id: 'minimalist',
		muiTheme: minimalistMuiTheme,
		name: 'Minimalist',
		theme: minimalistTheme,
	},
	midnight: {
		id: 'midnight',
		muiTheme: midnightMuiTheme,
		name: 'Midnight',
		theme: midnightTheme,
	},
	babyBlue: {
		id: 'babyBlue',
		muiTheme: babyBlueMuiTheme,
		name: 'Baby Blue',
		theme: babyBlueTheme,
	},
	blueSteel: {
		id: 'blueSteel',
		muiTheme: blueSteelMuiTheme,
		name: 'Blue Steel',
		theme: blueSteelTheme,
	},
	braveNewWorld: {
		id: 'braveNewWorld',
		muiTheme: braveNewWorldMuiTheme,
		name: 'Brave New World',
		theme: braveNewWorldTheme,
	},
	city: {
		id: 'city',
		muiTheme: cityMuiTheme,
		name: 'City',
		theme: cityTheme,
	},
	snow: {
		id: 'snow',
		muiTheme: snowMuiTheme,
		name: 'Snow',
		theme: snowTheme,
	},
	rubyTuesday: {
		id: 'rubyTuesday',
		muiTheme: rubyTuesdayMuiTheme,
		name: 'Ruby Tuesday',
		theme: rubyTuesdayTheme,
	},
	whimsy: {
		id: 'whimsy',
		muiTheme: whimsyMuiTheme,
		name: 'Whimsy',
		theme: whimsyTheme,
	},
	woodDark: {
		id: 'woodDark',
		muiTheme: woodDarkMuiTheme,
		name: 'Wood Dark',
		theme: woodDarkTheme,
	},
} as const

export const themesOrder: Array<keyof typeof themesIndex> = [
	'flatWhite',
	'rubyTuesday',
	//'playful',
	'babyBlue',
	'blueSteel',
	//'brettWasHere',
	'darkMatter',
	'braveNewWorld',
	'whimsy',
	'snow',
	'woodDark',
	'city',
	'midnight',
	'minimalist',
]
