import type {
	InfiniteData,
	QueryClient,
	QueryFunction,
	QueryFunctionContext,
	QueryObserverOptions,
} from 'react-query'
import { uniqueBy } from 'remeda'

import { InactiveTasksRange, Task } from '../../types'
import type {
	ApiAdapter,
	ApiTaskListResult,
	TaskListParams,
} from '../api/baseApiAdapter'
import { taskKeys } from '../queries/tasks/taskKeys'
import { getQueryLastUpdated, setQueryLastUpdated } from '../queries/utils'
import type { StoreContext } from '../store-types'
import updateTaskQueryCacheWithList from '../utils/updateTaskQueryCacheWithList'
import { projectKeys } from './projects-keys'
import type { ProjectDetail, ProjectListInactiveFrom } from './projects-types'

const mergeLastUpdatedTaskListResults = <T extends ApiTaskListResult<Task>>(
	listA: T,
	listB: T
): T => {
	const items = uniqueBy([...listA.items, ...listB.items], (item) => item.id)

	return {
		...listA,
		count: items.length,
		items,
	}
}

const flattenInfiniteListResult = <TData>(
	listResult:
		| ApiTaskListResult<TData>
		| InfiniteData<ApiTaskListResult<TData>>
): ApiTaskListResult<TData> => {
	if ('pages' in listResult) {
		const items = listResult.pages.flatMap((page) => page?.items || [])
		return {
			items,
			count: items.length,
			page: 1,
			pageSize: items.length,
			hasMore: false,
			privateTaskCount: 0,
		}
	}
	return listResult
}

export const createFetchProjectListQueryFn =
	({
		apiAdapter,
		queryClient,
	}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>): QueryFunction<
		ApiTaskListResult<Task> | undefined
	> =>
	async ({ queryKey, signal }) => {
		const params: TaskListParams = queryKey[2] || {}
		const prevResult = queryClient.getQueryData<
			ApiTaskListResult<Task> | InfiniteData<ApiTaskListResult<Task>>
		>(queryKey)

		const paramsWithMeta = Object.assign({}, params, { isProject: true })

		const lastUpdated = await getQueryLastUpdated(queryKey)
		if (lastUpdated && prevResult) {
			paramsWithMeta.updatedAfter = lastUpdated
		}

		const result = await apiAdapter.tasks.getList(paramsWithMeta, {
			signal,
		})
		if (result) {
			let mergedResult = result
			// Merge with previous data
			if (lastUpdated && prevResult) {
				mergedResult = mergeLastUpdatedTaskListResults(
					flattenInfiniteListResult(prevResult),
					result
				)
			}

			updateTaskQueryCacheWithList(queryClient, result.items)
			setQueryLastUpdated(queryKey)

			return mergedResult
		} else {
			return queryClient.getQueryData(queryKey)
		}
	}

export const getProjectsOverviewListQueryKey = (
	inactiveFrom: ProjectListInactiveFrom
) =>
	projectKeys.list({
		isProject: true,
		inactiveFrom,
		statusCodes: inactiveFrom === 'none' ? 'active' : 'all',
	})

export const fetchProject =
	(
		apiAdapter: ApiAdapter,
		queryClient: QueryClient,
		projectId: string,
		inactiveFrom: InactiveTasksRange = 'none'
	) =>
	async ({ signal }: QueryFunctionContext): Promise<ProjectDetail> => {
		// TODO: use `fetchTaskList` from '../queries/tasks.ts'
		const promise = apiAdapter.tasks.getList(
			{
				ancestorId: projectId,
				inactiveFrom,
				includeRoot: true,
				pageSize: 0,
				statusCodes: inactiveFrom === 'none' ? 'active' : 'all',
			},
			{ signal }
		)

		const { items } = await promise

		updateTaskQueryCacheWithList(queryClient, items)

		const filterAncestors = (ancestor: Task, tasks: Task[]) => {
			const ancestorIds = ancestor.parents.map((parent) => parent.id)
			return tasks.filter((task) => !ancestorIds.includes(task.id))
		}

		const ancestor = items.find((task) => task.id === projectId)
		let tasks = items
		if (ancestor) {
			tasks = filterAncestors(ancestor, items)
		}

		return tasks.reduce<ProjectDetail>(
			(acc, task) => {
				if (task) {
					if (projectId === task.id) {
						acc.ancestor = task.id
					} else {
						acc.descendants.push(task.id)
					}
				}
				return acc
			},
			{ ancestor: projectId, descendants: [] }
		)
	}

export const createProjectDetailQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	(
		projectId: string,
		inactiveFrom?: InactiveTasksRange
	): QueryObserverOptions => ({
		queryKey: projectKeys.detail(projectId, { inactiveFrom }),
		queryFn: fetchProject(apiAdapter, queryClient, projectId, inactiveFrom),
	})
